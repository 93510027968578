.__pr-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.__pr-report-table .ant-table-body table {
    width: inherit;
}

.__pr-user-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.__pr-work-item {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
}

.__pr-header-total {
    display: flex;
    flex-direction: column;
}

.__pr-header-total-value {
    color: var(--primary-color);
    font-weight: bold;
}