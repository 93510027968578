/* Monthly Planning */

.planning-weekly {
    overflow-x: auto;
}

.monthly-planning-table {
    height: 100%;
    position: relative;
    display: block;
    overflow-x: scroll;
    /* white-space: nowrap; */
    /* position: relative; */
    /* margin-bottom: 20px; */
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    /* box-shadow: var(--box-shadow); */
    border: none;
    border-collapse: separate;
    border-spacing: 0;
}

.special-click-active:active {
    background-color: rgba(187, 99, 19, 0.5) !important;
}

.monthly-planning-table th {
    position: sticky;
    top: 0px;
    text-align: center;
    font-weight: normal;
    background-color: white;
    z-index: 2;
    border-right: 1px solid whitesmoke;
}

tbody:nth-child(2n+0) .monthly-planning-fixed-users {
    background-color: #D1D1D1;
    /* border-right: 1px solid #D1D1D1; */
}

tbody:nth-child(2n+1) .monthly-planning-fixed-users {
    background-color: #DEDEDE;
    /* border-right: 1px solid #DEDEDE; */
}

.monthly-planning-table th {
    background-color: white;
}

.monthly-planning-table th:first-child {
    border-radius: 3px 0px 0px 0px;
}

.monthly-planning-table th:last-child {
    border-radius: 0px 3px 0px 0px;
}

/* .monthly-planning-table,
.monthly-planning-table th,
.monthly-planning-table td {
    outline: 1px solid rgba(235, 235, 235, 0.7);
} */

/* .monthly-planning-table tbody tr:hover {
    filter: brightness(90%) grayscale(30%);

} */

/* .monthly-planning-table,
.monthly-planning-table th,
.monthly-planning-table td  {
    border-bottom: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
} */

.monthly-planning-table tbody:nth-child(odd) {
    background-color: rgb(255, 255, 255);
}


.monthly-planning-fixed-users-min-width {
    min-width: 200px !important;
}

.monthly-planning-table td {
    padding: 10px 8px;
    position: relative;
    border-right: 1px solid whitesmoke;
}

.monthly-planning-fixed-users {
    position: sticky !important;
    left: 0px;
    z-index: 3;
    /* background-color: #F0D5C0; */
    width: 150px;
    max-width: 150px;
    /* background-color: white; */
    /* box-shadow: inset -1px 0 0px 0px whitesmoke; */
}


/* th.monthly-planning-fixed-users {
    border-right: 1px solid white;
} */

.monthly-planning-fixed-users p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.monthly-planning-fixed-users span {
    display: flex;
    justify-content: space-between;
}

.monthly-planning-tbody-empty-body {
    background-color: #e4e4e4 !important;
}

.monthly-planning-tbody-empty-row {
    background-color: #e4e4e4 !important;
    height: 46px;
}

/* Cell */

.monthly-planning-cell {
    vertical-align: baseline;
    transition: 0.22s;
    border: none;
}

.monthly-planning-cell:hover .monthly-planning-cell-buttons {
    animation: fade-out 0.2s reverse forwards;
    display: inline;
}

.monthly-planning-cell:hover {
    background-color: #DFC2AA !important;
}

.__monthly-planning-selection-activated .__monthly-planning-cell {
    filter: opacity(0.3);
}

.__monthly-planning-selection-activated .monthly-planning-cell-selection-on {
    filter: opacity(1);
    transform: translateY(-4px);

}

/* .monthly-planning-cell-selection-off {
    filter: opacity(0.3);
} */

/* .monthly-planning-cell-selection-on {
    /* animation: zoom-in-out 1s infinite; 
} */

.monthly-planning-cell-content {
    min-height: 32px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: default;
    user-select: none;
    max-width: 139px;
    width: 139px;
    box-shadow: var(--box-shadow);
}

.monthly-planning-cell-content-boxed {
    display: flex;
    align-content: center;
    justify-content: space-between;
    min-height: 32px;
    padding: 4px 0;
    max-width: 120px;
    width: 120px;
}

.monthly-planning-cell-content-boxed-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 31px;
    max-width: 120px;
    padding: 0px;
    width: 31px;
}

.monthly-planning-cell-content p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.__breaktime-and-overtime-icons {
    line-height: 15px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 1px; */
}

.__breaktime-count {
    font-size: 12px;
}

.__overtime-count {
    font-size: 12px;
}

.monthly-planning-cell-content-small {
    font-size: 14px;
    width: 33px !important;
    height: 33px !important;
}

.monthly-planning-cell-th-content-small {
    font-size: 14px;
    height: 70px !important;
}

.monthly-planning-cell-overtime {
    background: repeating-linear-gradient(-55deg,
            var(--overtime-primary-color),
            var(--overtime-primary-color) 10px,
            var(--overtime-secondary-color) 10px,
            var(--overtime-secondary-color) 20px);
    color: white;
}

.monthly-planning-cell-undertime {
    background: repeating-linear-gradient(-55deg,
            var(--undertime-primary-color),
            var(--undertime-primary-color) 10px,
            var(--undertime-secondary-color) 10px,
            var(--undertime-secondary-color) 20px);
    color: white;
}

.monthly-planning-cell-oncontextmenu {
    background-color: rgba(187, 99, 19, 0.5) !important;
}

.__event-tab-without-padding .ant-card-body {
    padding: 0 !important;
}


/* @media only screen and (max-width: 900px) {
    .monthly-planning-fixed-users-data  p{
        transform: rotate(-90deg);
        text-align: center;
        max-width: 80px;
        overflow: hidden;
    }
} */


/* 
 * New monthly planning design 
*/

.__monthly-planning-cell {
    position: relative;
    padding: 4px 8px;
    border-radius: 8px;
    width: 100%;
    ;
    /* transition: filter 0.2s ease-out; */
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0); */
}

.__monthly-planning-cell-dropdown-contextmenu {
    overflow: hidden;
}

.__monthly-planning-cell-actions {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 4px;
    width: 0%;
    /* bottom: calc(50% - 12.5px); */
    bottom: -16px;
    left: 0px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out 0s, width 0s linear 0s;
    padding-top: 4px;
    padding-bottom: 4px;
}

.__monthly-planning-actions-activated .__monthly-planning-cell:hover .__monthly-planning-cell-actions.__canedit {
    opacity: 1;
    width: 100%;
    transition-delay: 0.2s;
    /* background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(48, 48, 48, 0.11) 30%, transparent 60%); */
}

.__monthly-planning-cell-action-edit,
.__monthly-planning-cell-action-delete,
.__monthly-planning-cell-action-copy,
.__monthly-planning-cell-action-cut,
.__monthly-planning-cell-action-paste {
    border-radius: 50px;
    /* border: 0.5px solid whitesmoke; */
    background-color: var(--btn-background-color);
    color: var(--btn-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    transition: all 0.2s ease-in-out, opacity 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.25) 0px -1px 3px 1px;


}

.__monthly-planning-cell-action-paste {
    background-color: transparent;
    border: none;
    box-shadow: none;
}


.__monthly-planning-cell-action-paste {
    width: 30px;
    height: 30px;
}

.__monthly-planning-cell-action-paste-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 0px;
    height: 0px;
}

.__monthly-planning-copy-activated .ant-table-cell:hover .__monthly-planning-cell-action-paste-parent.__canedit {
    opacity: 0.93;
    transition-delay: 0s;
    z-index: 1;
    transform: translate(-50%, -50%) scale(1);
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 8px;
    background-color: var(--primary-color);
}

.__monthly-planning-cell-action-edit:hover,
.__monthly-planning-cell-action-delete:hover,
.__monthly-planning-cell-action-copy:hover,
.__monthly-planning-cell-action-cut:hover {
    transition-delay: 0s;
    filter: brightness(92%);
    transform: translateY(-2px);
}

/* .__monthly-planning-cell p,
.__monthly-planning-cell span {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-font-smoothing: antialiased !important;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    transform: translateZ(0);

} */

/* .__monthly-planning-main-table .__monthly-planning-cell:hover {
    filter: brightness(1.2);
} */
.hvr-reveal:before {
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    border-radius: 8px;

}

.hvr-reveal:active:before,
.hvr-reveal:focus:before,
.hvr-reveal:hover:before {
    border-width: 3px;
}

.dark.hvr-reveal:before {
    border-color: #00000066;
}

.white.hvr-reveal:before {
    border-color: #ffffff66;
}

.__monthly-planning-main-table .hvr-float-shadow:active:before,
.__monthly-planning-main-table .hvr-float-shadow:focus:before,
.__monthly-planning-main-table .hvr-float-shadow:hover:before {
    opacity: 0.3 !important;
}

/* .__monthly-planning-btn-filter.ant-btn-primary svg {
    fill: white !important;
} */

.__monthly-planning-main-table .hvr-float:active,
.__monthly-planning-main-table .hvr-float:focus,
.__monthly-planning-main-table .hvr-float:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}

.__monthly-planning-main-table.__monthly-planning-copy-activated .hvr-float:active,
.__monthly-planning-main-table.__monthly-planning-copy-activated .hvr-float:focus,
.__monthly-planning-main-table.__monthly-planning-copy-activated .hvr-float:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.__monthly-planning-main-table .__monthly-planning-cell-icons,
.__monthly-planning-main-table .__mp-e-icons {
    line-height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.__monthly-planning-main-table .__monthly-planning-cell-icon,
.__monthly-planning-main-table .__mp-e-icon {
    font-size: 12px;
}

.__monthly-planning-main-table td.ant-table-cell {
    padding: 10px 9px !important;
    vertical-align: top;

}

/* .__monthly-planning-main-table td.ant-table-cell .__monthly-planning-cell:not(:last-child) {
    margin-bottom: 10px;

} */

.__monthly-planning-main-table td.ant-table-cell:hover {
    /* background-color: var(--primary-color)45!important; */
    background-color: #e5e5e5 !important;
}

.__monthly-planning-main-table .__monthly-planning-cell-hover {
    background-color: var(--background-color) !important;
}

.__monthly-planning-main-table .ant-table-row {
    height: 70px !important;
}

.__monthly-planning-main-table th {
    background-color: var(--background-color) !important;
    height: 70px !important;
}

.__monthly-planning-main-table th:not(:first-child) {
    text-align: center !important;

}

td.ant-table-cell-row-hover {
    background: var(--primary-color-hover) !important;
}

.__monthly-planning-main-table .ant-table-tbody>tr.ant-table-row:hover>td,
.__monthly-planning-main-table .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: var(--primary-color-hover) !important;
}

.__monthly-planning-one-cell .__monthly-planning-one-cell-content:not(:last-child) {
    margin-bottom: 10px;
}

.__monthly-planning-one-cell-content {
    border-radius: 8px;
}

.__monthly-planning-one-cell-content .__monthly-planning-cell-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    border-radius: 8px;
}

.__monthly-planning-one-cell-content .__monthly-planning-cell-overlay .plus,
.__monthly-planning-one-cell-content .__monthly-planning-cell-overlay .minus {
    display: none;
}

.__monthly-planning-one-cell-content.isAdding .__monthly-planning-cell,
.__monthly-planning-one-cell-content.isRemoving .__monthly-planning-cell {
    background-color: #666 !important;
    color: white !important;

}

.__monthly-planning-one-cell-content.isAdding .__monthly-planning-cell-overlay {
    color: white;
    display: flex;
    background-color: #558b2fa7 !important;

}

.__monthly-planning-one-cell-content.isAdding .__monthly-planning-cell-overlay .plus,
.__monthly-planning-one-cell-content.isRemoving .__monthly-planning-cell-overlay .minus {
    display: block;
}

.__monthly-planning-one-cell-content.isRemoving .__monthly-planning-cell-overlay {
    background-color: #e53935a7 !important;
    color: white;
    display: flex;
}

.__monthly-planning-main-table th.__monthly-planning-column-weekend,
.__monthly-planning-main-table th.__mp-col-we,
.__monthly-planning-main-table td.__monthly-planning-column-weekend,
.__monthly-planning-main-table td.__mp-col-we {
    background-color: #589dd620 !important;
}

.__monthly-planning-main-table th.__monthly-planning-column-today,
.__monthly-planning-main-table th.__mp-col-today,
.__monthly-planning-main-table td.__monthly-planning-column-today,
.__monthly-planning-main-table td.__mp-col-today {
    background-color: #fbc02d20 !important;
}

.__monthly-planning-main-table th.__monthly-planning-column-today {
    font-weight: bold;
}

.__monthly-planning-main-table th.__monthly-planning-column-public-holiday,
.__monthly-planning-main-table td.__monthly-planning-column-public-holiday {
    background-color: #558b2f20 !important;
}

.__monthly-planning-main-table th.__monthly-planning-column-users-stats {
    background-color: var(--primary-color) !important;
}

.__monthly-planning-main-table th.__monthly-planning-column-blocked,
.__monthly-planning-main-table td.__monthly-planning-column-blocked {
    background-color: #e5393520 !important;

}

.__monthly-planning-main-table td,
.__monthly-planning-main-table th {
    background-color: white;
    border-right: 1px solid rgb(198, 198, 198);
}

.__monthly-planning-main-table .ant-table-cell-fix-left-last {
    border-right: 1px solid rgb(198, 198, 198);
}

.__monthly-planning-main-table .ant-table-cell-fix-right-first {
    border-left: 1px solid rgb(198, 198, 198);
}

.__monthly-planning-main-table p.__monthly-planning-column-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 190px;
}

.__monthly-planning-main-table p.__monthly-planning-column-name-desc {
    font-size: 70%;
    font-style: italic;
}

.__monthly-planning-main {
    display: flex;
    gap: 15px;
    transition: all 0.1s;
}

.__monthly-planning-main * {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.__monthly-planning-month-picker.ant-picker-large .ant-picker-input input {
    text-transform: capitalize;
    font-size: 20px;
}

.__monthly-planning-month-picker.ant-picker-large {
    padding-left: 0px;
    padding-right: 0px;
}

.__monthly-planning-main.nofilters {
    gap: 0px
}


.__monthly-planning-main-table-container {
    flex: 1;
    overflow-x: auto;
}

.__monthly-planning-main-filters {
    transition: width 0.1s ease-in-out, padding 0.1s ease-in-out, opacity 0.05s ease-in-out, border 0.1s;
    /* transition-delay: 0.5s border; */
    width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #c6c6c6;
    padding: 15px;
    overflow: hidden;
}

.__monthly-planning-main-small-width {
    width: 280px;
}

.__monthly-planning-main-filters-content {
    max-height: calc(100vh - 285px);
    overflow: auto;
    overflow-x: hidden;
}

.__monthly-planning-main-filters.nofilters {
    width: 0px;
    padding: 15px 0px;
    border: 0px;
    overflow-y: hidden;
    opacity: 0;
}

/* .__monthly-planning-main-filters.nofilters .ant-card,
.__monthly-planning-main-filters.nofilters .ant-card .ant-card-head,
.__monthly-planning-main-filters.nofilters .ant-card .ant-card-body {    
    width: 0px;
} */

.__monthly-planning-fill-td {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.__monthly-planning-cell-blocked {
    background-color: #e5393520 !important;
}

.__monthly-planning-cell-partially {
    background-color: #fbc12d4b !important;
}


@media only screen and (min-width: 901px) {
    .__monthly-planning-month-picker.ant-picker-large {
        width: 100px !important;
    }
}

@media only screen and (max-width: 900px) {
    .__monthly-planning-month-picker.ant-picker-large .ant-picker-input input {
        font-size: 18px;
    }

    .__monthly-planning-month-picker.ant-picker-large {
        width: 70px !important;
    }
}

.__e-icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: unset;
}

.__e-icons.wrap {
    flex-wrap: wrap
}

.__flex-column {
    display: flex;
    flex-direction: column;
}

.__e-title {
    text-overflow: ellipsis;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    margin-top: -3px
}

.__e-times {
    font-size: 12px
}

.__mp-u {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.__mp-u>.__mp-u-info>div:nth-child(2) {
    font-size: 70%;
    font-style: italic;
}