/* body,
.__container-external-app {
    height: 100vh;
}

@supports (-webkit-touch-callout: none) {

    body,
    .__container-external-app {
        height: -webkit-fill-available;
    }
} */

html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}


.__container-external-app {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.__dark .ant-form-item-control-input .ant-input-affix-wrapper,
.__dark .ant-input-affix-wrapper {
    background-color: #212121 !important;
}

.__dark .ant-form-item-control-input .ant-input-suffix .ant-input-show-count-suffix,
.__dark .ant-input-suffix .ant-input-show-count-suffix {
    color: white;
}

.__dark .ant-form-item-control-input #editAvailability_title,
.__dark .ant-input-affix-wrapper>input {
    background-color: #212121;
    color: white;
}

.__dark h4.MuiDatePickerToolbar-title {
    color: white;
}

.__dark .ant-checkbox-wrapper span {
    color: white
}

.__dark .anticon-clock-circle {
    color: whitesmoke !important;
}

.__dark .ant-divider {
    border-top: 1px solid #ffffff22;
}


/* #region Select dark mode */
.__dark .ant-select {
    color: white;
    background-color: #212121;
}

.__dark .ant-select-selector {
    color: white;
    background-color: #212121 !important;
}

.__dark .ant-select-arrow {
    color: white;
}

.__dark .ant-select-dropdown {
    color: white;
    background-color: #212121 !important;

}

.__dark .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.__dark .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: white;
    background-color: #303030 !important;

}

.__dark .__weekdays-buttons.circle-button {
    background-color: #212121;
    border-color: white;
    color: white;
}

.__dark .__wekdays-buttons.circle-button.ant-btn-primary {
    color: #303030
}

.__dark .ant-radio-wrapper span {
    color: white;
}

.__dark .ant-input-number {
    color: white;
    background-color: #212121;
}