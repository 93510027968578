.time-control-table .ant-table th {
    width: fit-content;
}

.time-control-table .ant-table .ant-avatar-lg {
    width: 44px;
    height: 44px;
    line-height: 44px;
}

.time-control-table-clocked-item,
.time-control-table-clocked-item-modified,
.time-control-table-clocked-item-modified-data {
    margin: 0;
    margin-right: 10px;
    background: #f5f5f5;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.2s;
    width: 80%;
    display: inline-block;
}

.time-control-table-clocked-item-modified {
    text-decoration: line-through;
}

.time-control-table-clocked-item-modified-data {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    color: #096dd9;
}

.planning-show-timeclock-button {
    margin-left: 5px;
    margin-right: 5px;
}

.planning-show-timeclock-status {
    font-weight: 600;
}

.time-control-table-clocked-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.time-control-table-clocked-item.manual {
    border: 1px solid #ffd591;
    background-color: #fff7e6;
    color: #d46b08;
}

.time-control-table-clocked-item.not-started {
    border: 1px solid #ffa39e;
    background-color: #fff1f0;
    color: #cf1322;
}

.time-control-table-clocked-item.running {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    color: #096dd9;
    display: inline-grid;
    grid-template-columns: 1fr 10px 1fr;
    grid-column-gap: 10px;
    padding-right: 15px;
    padding-left: 15px;
}


.time-control-table-clocked-item.running div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeclock-user {
    text-align: start !important;
}

.timeclock-control-table-event-cell {
    padding: 5px 8px;
    height: 50px;
    border-radius: 8px;
    width: 90%;
    background: #f5f5f5;
    border: 1px solid #c6c6c6;
    text-align: justify !important;
    margin-left: 5%;
}

.timeclock-control-table-event-cell .timeclock-control-table-event-cell-icons {
    margin-top: -4px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.timeclock-control-table-event-cell .timeclock-control-table-event-cell-icon {
    font-size: 14px;
}

td.timeclock-control-table-fixed-even.timeclock-user {
    text-align: start !important;
}

.timeclock-control-plan-badge {
    display: inline-grid;
    grid-template-columns: 1fr 15px 1fr;
    grid-column-gap: 10px;
    width: 100%;

}

/* .timeclock-control-plan-badge span {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.__event-parent {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.__event-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}


.__event-title,
.__event-times {
    transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);
    transition-delay: 0s;
    width: 100%;
}

.__event-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    transform-origin: top left;
}

.__event-times {
    transform-origin: bottom left;
}

.timeclock-control-plan-badge:hover .__event-times,
.timeclock-control-plan-badge:hover .__event-title {
    transition-delay: 0.2s;
}

.timeclock-control-plan-badge:hover .__event-times {
    transform: scale(1.5) translateY(-4.5px);
}

.timeclock-control-plan-badge:hover .__event-title {
    opacity: 0;
}


.timeclock-control-table-fixed-even,
.timeclock-control-table-fixed-odd {
    text-align: center !important;
}

th.timeclock-control-table-fixed-even,
th.timeclock-control-table-fixed-even-main {
    background-color: #f5f5f5 !important;
    text-align: center !important;

}

td.timeclock-control-table-fixed-even {
    background-color: #fafafa;

}

th.timeclock-control-table-fixed-odd,
th.timeclock-control-table-fixed-odd-main {

    background-color: #cfd8dc7c !important;
    text-align: center !important;
}

td.timeclock-control-table-fixed-odd {

    background-color: #eceff1c9;
}

.time-clock-control-running-icon {

    animation: rotating 2s linear infinite;
}

.init-device-modal .map-pin-number-case {
    padding-top: 40px;
    padding-bottom: 40px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    display: flex;
    justify-content: center;
    font-size: 6em;
    font-weight: 600;
    height: 100%;
}

.__time-clock-filter-day,
.__time-clock-filter-day-selected {
    display: flex;
    align-items: center;
    padding: 15px 8px;
    height: 50px;
    font-size: 18px;
    transition: background-color 0.2s;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    border: 1px solid transparent;
    transition: all 0.2s;
}

.__time-clock-content {
    display: flex;
    width: 100%;
    gap: 15px;
}

.__time-clock-data {
    /* Croît pour occuper l'espace restant */
    flex: 1 1 auto;
    /* Pour éviter les débordements */
    min-width: 0;
}

.__time-clock-filters {
    /* Largeur fixe de 130px, sans croissance ni réduction */
    flex: 0 0 130px;
    transition: all 0.2s;

    padding-right: 10px;
    border: 0px;
    border-right: 1px solid #c6c6c6;
    border-style: solid;
    border-color: #c6c6c6;
    overflow: hidden;
    opacity: 1;
}

.__time-clock-filters2 {
    /* Largeur fixe de 400px, sans croissance ni réduction */
    flex: 0 0 400px;
    transition: all 0.2s;

    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #c6c6c6;
    padding: 15px;
    overflow: hidden;
    position: relative;
    opacity: 1;
}

.hidden-sidebar {
    /* Réduit la largeur à 0 */
    flex-basis: 0;
    margin: 0;
    padding: 0px;
    border: 0px;
    opacity: 0;
}

.__time-clock-filters.__filter-hidden {
    width: 0px;
    border-color: transparent;
    overflow-y: hidden;
    opacity: 0;
}

.__time-clock-filter-day:hover {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    color: var(--primary-color);
}

.__time-clock-filter-day-selected {
    background-color: rgba(var(--primary-color-rgb), 0.2);
    color: var(--primary-color);
    border: 1px solid rgba(var(--primary-color-rgb), 0.3);
}

.container-content-background-timeclock-fullscreen {
    border-radius: 0px;
    height: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 30;
    position: fixed;
    padding: 0px;
}

.container-content-background-timeclock-fullscreen .timeclock-tabs-parent {
    margin: 20px;
}

.timeclock-device-add-step-number {
    font-size: 20px;
    border-radius: 20px;
    height: 10px;
    width: 10px;
    background: red;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}