.actions-toolbar {
    opacity: 0;
    pointer-events: none;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 14px;
    min-width: 400px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    transition: all .22s;

    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.actions-toolbar.show {
    bottom: 8px;
    pointer-events: all;
    opacity: 1;
}

.actions-toolbar>div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;

}

.actions-toolbar .quit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}