.sunkhronos-news-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sunkhronos-news-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sunkhronos-news-title {
    display: -webkit-box;
    font-weight: bold;
    position: relative;
    flex: 1;
    overflow: hidden;
    line-clamp: 1;
    -webkit-line-clamp: 1; /* Limits content to 4 lines */
    -webkit-box-orient: vertical;
    max-width: 70%;
}

.sunkhronos-news-tag {
    width: 30%;
    text-align: end;    
}

.sunkhronos-news-date {
    font-style: italic;
    font-size: 0.8rem;
    color: gray;
}

.sunkhronos-news-body {
    margin-top: 0.5rem;
    flex: 1;
}

.sunkhronos-news-content {
    /* display: -webkit-box; */
    /* line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sunkhronos-news-content.expanded {
    line-clamp: unset;
    -webkit-line-clamp: unset; /* Removes the line limit */
}

.sunkhronos-news-footer {
    margin-top: 0.5rem;
}

.sunkhronos-news-image {
    max-height: 20rem;
    max-width: 100%;
}

.sunkhronos-news-image-container {
    display: flex;
    position: relative;
    justify-content: center;
}

.sunkrhonos-news-readmore {
    width: 100%;
    text-align: end;
}

