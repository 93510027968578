.__font-aweseome-icon-picker-grid {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.__font-aweseome-icon-picker-grid .__icon-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    min-width: 30px;
    min-height: 30px;
}

.__font-aweseome-icon-picker-grid .__icon-item.__selected {
    outline: 1px solid var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border-radius: 3px;
}

.__clickable {
    cursor: pointer;
}

.__fa-picker-modal-opener {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    outline: 1px solid rgb(119, 119, 119);
    transition: all .2s ease-in-out;
}

.__fa-picker-modal-opener.ant-popover-open {
    outline: 1px solid var(--primary-color);
    background-color: rgba(var(--primary-color-rgb), 0.1);
    color: var(--primary-color);
    transform: scale(1.07);

}