/* ImageUploader */

.image-uploader-parent {
    border: var(--dark-color) 1px dashed;
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: transparent;
    border-radius: var(--border-radius);
    height: 150px;
    width: 150px;
}

.image-uploader-parent:hover {
    border-color: var(--primary-color);
}

.image-uploader-input {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.image-uploader-content {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 5px; */
    font-size: 16px;
    background-color: transparent;
}

.image-uploader-img {
    max-width: 100%;
    max-height: 100%;
}

.image-uploader-drag {
    cursor: copy !important;
    background-color: var(--background-color);
}

.image-uploader-delete {
    position: absolute;
    top: 2px;
    right: 2px;
}

.image-uploader-delete span {
    margin-top: 5px;
}

/* FileUploader */

.file-uploader-parent {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: transparent;
    border-radius: var(--border-radius);
    border: var(--dark-color) 1px dashed;

}

.file-uploader-parent-disabled {
    position: relative;
    overflow: hidden;
    display: inline-block;
    background-color: transparent;
    border-radius: var(--border-radius);
    border: #ccc 1px dashed;
    color: #ccc;

}

.file-uploader-content {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    font-size: 16px;
    background-color: transparent;
    gap: 5px;
}

.file-uploader-parent:hover {
    border: 1px dashed var(--primary-color);
    color: var(--primary-color);
}

.file-uploader-divider {
    height: 1px;
    width: 100%;
    background-color: var(--border-color);
}

.file-uploader-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: 5px 0px 0px 0px;
}

/* InputField */

.inputfield-content {
    display: flex;
    flex-direction: column;
}

.inputfield-error {
    animation: shake 0.5s;
}

.inputfield-error .ant-input,
.inputfield-error .ant-input-group-addon {
    border-color: var(--error-color);
}

.inputfield-error-text {
    color: var(--error-color);
    /*animation: expand-appear 1s;*/
    font-style: italic;
    font-size: 82%;
    padding-left: 13px;
}

/* TagField */

.tagfield-icon:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.tagfield-tags {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
    min-height: 20px;
    width: 100%;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    padding: 5px;
}

.tagfield-tags::after {
    content: '';
    border-top: 15px solid transparent;
    border-bottom: 15px solid var(--background-color);
    border-right: 15px solid transparent;
    transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    bottom: -5px;
}

.tagfield-tag {
    margin: 3px;
    border-radius: var(--border-radius);
    position: relative;
}

.tagfield-tag:not(:first-child) {
    animation: appear-from-right 0.5s;
}

/* TableTransfer */

.tabletransfer-parent {
    overflow: scroll;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
}

.tabletransfer-parent .anticon-left,
.tabletransfer-parent .anticon-right {
    transform: rotate(180deg);
    padding-right: 4px;
    padding-top: 2px;
}

.tabletransfer-parent .ant-transfer-list {
    border-radius: var(--border-radius);
}

.tabletransfer-parent .ant-transfer-list-header {
    border-radius: 18px 18px 0 0;
}

.tabletransfer-parent .ant-btn-icon-only.ant-btn-sm {
    border-radius: var(--border-radius);
    height: 32px;
    width: 32px;
    margin: 20px 10px;
}

.tabletransfer-parent .ant-transfer-operation {
    flex-direction: column-reverse;

}

.tabletransfer-parent .ant-transfer-operation .ant-btn .anticon {
    padding: 0px !important;

}


/* CircleButton */

.circle-button-tooltip .ant-tooltip-inner {
    background-color: white;
    color: var(--primary-color);
}

.circle-button-tooltip .ant-tooltip-arrow::before {
    background-color: white;
}

/* .circle-button svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
} */

.circle-button {
    width: 42px;
    height: 42px;
    transition: all 0.3s ease-in-out;
    /* background-color: var(--background-color);
    box-shadow: var(--box-shadow); */
}

.circle-button-small {
    width: 33px;
    height: 33px;
    transition: all 0.3s ease-in-out;
    /* background-color: var(--background-color); */
    /* box-shadow: var(--box-shadow); */
}

.circle-button.ant-btn-link,
.circle-button-small.ant-btn-link {
    width: 32px;
    height: 32px;

}

.circle-button:hover:not(:disabled),
.circle-button-small:hover:not(:disabled) {
    /* animation: tilt-right 0.5s; */
    /* background-color: var(--background-color); */
    filter: brightness(92%);
    transform: translateY(-2px);
}

/* SpeedDial */

.speed-dial {
    position: absolute;
    display: flex;
    justify-content: center;
    right: 65px;

    border-radius: 50px;
    top: -5px;
    gap: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: hidden;
    background-color: #F2F2F2CC;
    width: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.speed-dial.__open {
    opacity: 1;
}

.speed-dial.__open .speed-dial-button {
    animation: speed-dial-animation 0.4s ease-in-out;
    animation-fill-mode: both;
}

/* .speed-dial-button:not(:first-child) {
    margin-left: 5px;
} */

@keyframes speed-dial-animation {
    from {
        opacity: 0;
        transform: scale(0);
    }

    ;

    to {
        opacity: 1;
        transform: scale(1);
    }

    ;
}

@media only screen and (max-width: 1600px) {

    /* TableTransfer */
    .tabletransfer-parent {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .tabletransfer-parent .ant-transfer-operation {
        flex-direction: row-reverse;
    }

    .tabletransfer-parent .anticon-left,
    .tabletransfer-parent .anticon-right {
        transform: rotate(270deg);
        padding-right: 4px;
        padding-top: 2px;
    }
}