/* Dashboard */

.dashboard-parent {
    height: 100%;
    width: 100%;
    text-align: center;
}

.dashboard-logo {
    width: 300px;
}

.dashboard-company-logo {
    max-height: 150px;
    max-width: 300px;
    /* margin-bottom: 40px; */
}

.dashboard-today {
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 20px;
}

.dashboard-card {
    margin-bottom: 20px;
}

.planning-statistics-card {
    height: calc(100vh - 20px);
}

.planning-statistics-card .dashboard-events {
    max-height: calc(100vh - 240px);
}

.dashboard-progress {
    animation: fade-out 3s;
}

.dashboard-empty {
    display: flex;
    align-items: center;
    line-height: 40px;
}

.dashboard-empty p {
    margin-left: 20px;
}

.dashboard-section {
    margin: 10px 0px;
    text-align: left;
}

.dashboard-section:first-child {
    margin-top: 20px;
}

.dashboard-section:last-child {
    margin-bottom: 0px;
}

.dashboard-section-title {
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    /* border-bottom: 1px solid var(--border-color); */
}

.dashboard-events {
    max-height: 450px;
    overflow: scroll;
}

.dashboard-event {
    display: flex;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    line-height: 32px;
}

.dashboard-event-dot {
    font-size: 35px;
    margin-right: 5px;
    padding-bottom: 3px;
}

.dashboard-event-model {
    width: 100% !important;
}

.dashboard-users {
    max-height: 300px;
    overflow: scroll;
}

.ant-table-row-expand-icon-cell {
    width: 30px !important;
}

.__dashboard-overtimes-actions-th {
    width: 85px !important;
    text-align: center !important;
}

/* ::-webkit-scrollbar-thumb,
.ant-layout-sider-dark::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 5px;
} */

.ant-layout-sider-dark::-webkit-scrollbar-track-piece,
.ant-layout-sider-dark::-webkit-scrollbar,
.ant-layout-sider-dark::-webkit-scrollbar-corner,
.ant-layout-sider-dark::-webkit-scrollbar-track {
    background-color: #232125;
}

::-webkit-scrollbar {
    background-color: var(--background-color);
    -webkit-appearance: none;
    appearance: none;
    width: 9px;
    height: 9px;

}

div::-webkit-scrollbar,
table::-webkit-scrollbar,
.ant-layout-sider-dark::-webkit-scrollbar,
.dashboard-events::-webkit-scrollbar,
.dashboard-scrollable::-webkit-scrollbar,
.types-of-day-list::-webkit-scrollbar,
.container-content-background::-webkit-scrollbar,
.dashboard-users::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    width: 9px;
    height: 9px;
}

/* .main-container::-webkit-scrollbar {
    -webkit-appearance: none;
    appearance: none;
    width:12px;
    height: 12px;

} */

::-webkit-scrollbar-corner,
div::-webkit-scrollbar-corner,
table::-webkit-scrollbar-corner,
.types-of-day-list::-webkit-scrollbar-corner,
.container-content-background::-webkit-scrollbar-corner,
.main-container::-webkit-scrollbar {
    background-color: var(--background-color);
}

.dashboard-events::-webkit-scrollbar-corner,
.dashboard-scrollable::-webkit-scrollbar-corner,
.dashboard-users::-webkit-scrollbar-corner {
    background-color: white;
}

::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
table::-webkit-scrollbar-thumb,
.dashboard-events::-webkit-scrollbar-thumb,
.dashboard-scrollable::-webkit-scrollbar-thumb,
.types-of-day-list::-webkit-scrollbar-thumb,
.container-content-background::-webkit-scrollbar-thumb,
.dashboard-users::-webkit-scrollbar-thumb,
.main-container::-webkit-scrollbar {
    border-radius: 5px;
    background-color: var(--border-color);
}

::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
}

.dashboard-user {
    margin-left: 10px;
    line-height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.dashboard-user-avatar {
    margin-right: 10px !important;
}

.dashboard-contract {
    line-height: 35px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}

.dashboard-scrollable {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.dashboard-scrollable-hvar {
    height: calc(100vh - 355px);
    overflow-y: auto;
    overflow-x: hidden;
}

.dashboard-occupancy-rate-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0px 15px 0px;
}

.dashboard-occupancy-rate-not-fullfilled {
    padding: 0px 5px 0px 5px;
    background-color: #b4282f;
    color: white;
    border-radius: 10px;
}

.dashboard-occupancy-rate-fullfilled {
    padding: 0px 5px 0px 5px;
    background-color: var(--success-color);
    color: white;
    border-radius: 10px;
}

.dashboard-occupancy-rate-fullfilled-over {
    padding: 0px 5px 0px 5px;
    background-color: #2196f3;
    color: white;
    border-radius: 10px;
}

.dashboard-occupancy-rate-line {
    /* border: 5px solid black; */
    padding: 0px 5px 0px 5px;
    color: white;
    border-radius: 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;
}

@media only screen and (max-width: 900px) {
    .dashboard-user-avatar {
        display: none;
    }
}