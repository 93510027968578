/* Monthly Planning */

.planning-weekly {
    overflow-x: auto;
}

.monthly-planning-table {
    height: 100%;
    position: relative;
    display: block;
    overflow-x: scroll;
    /* white-space: nowrap; */
    /* position: relative; */
    /* margin-bottom: 20px; */
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    /* box-shadow: var(--box-shadow); */
    border: none;
    border-collapse: separate;
    border-spacing: 0;
}

.special-click-active:active {
    background-color: rgba(187, 99, 19, 0.5) !important;
}

.monthly-planning-table th {
    position: sticky;
    top: 0px;
    text-align: center;
    font-weight: normal;
    background-color: white;
    z-index: 2;
    border-right: 1px solid whitesmoke;
}

tbody:nth-child(2n+0) .monthly-planning-fixed-users {
    background-color: #D1D1D1;
    /* border-right: 1px solid #D1D1D1; */
}

tbody:nth-child(2n+1) .monthly-planning-fixed-users {
    background-color: #DEDEDE;
    /* border-right: 1px solid #DEDEDE; */
}

.monthly-planning-table th {
    background-color: white;
}

.monthly-planning-table th:first-child {
    border-radius: 3px 0px 0px 0px;
}

.monthly-planning-table th:last-child {
    border-radius: 0px 3px 0px 0px;
}

/* .monthly-planning-table,
.monthly-planning-table th,
.monthly-planning-table td {
    outline: 1px solid rgba(235, 235, 235, 0.7);
} */

/* .monthly-planning-table tbody tr:hover {
    filter: brightness(90%) grayscale(30%);

} */

/* .monthly-planning-table,
.monthly-planning-table th,
.monthly-planning-table td  {
    border-bottom: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
} */

.monthly-planning-table tbody:nth-child(odd) {
    background-color: rgb(255, 255, 255);
}


.monthly-planning-fixed-users-min-width {
    min-width: 200px !important;
}

.monthly-planning-table td {
    padding: 10px 8px;
    position: relative;
    border-right: 1px solid whitesmoke;
}

.monthly-planning-fixed-users {
    position: sticky !important;
    left: 0px;
    z-index: 3;
    /* background-color: #F0D5C0; */
    width: 150px;
    max-width: 150px;
    /* background-color: white; */
    /* box-shadow: inset -1px 0 0px 0px whitesmoke; */
}


/* th.monthly-planning-fixed-users {
    border-right: 1px solid white;
} */

.monthly-planning-fixed-users p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.monthly-planning-fixed-users span {
    display: flex;
    justify-content: space-between;
}

.monthly-planning-tbody-empty-body {
    background-color: #e4e4e4 !important;
}

.monthly-planning-tbody-empty-row {
    background-color: #e4e4e4 !important;
    height: 46px;
}

/* Cell */

.monthly-planning-cell {
    vertical-align: baseline;
    transition: 0.22s;
    border: none;
}

.monthly-planning-cell:hover .monthly-planning-cell-buttons {
    animation: fade-out 0.2s reverse forwards;
    display: inline;
}

.monthly-planning-cell:hover {
    background-color: #DFC2AA !important;
}

.__mp-sel-act .__mp-event {
    filter: opacity(0.3);
}

.__mp-sel-act .__mp-one-cell-content.isAdding .__mp-event {
    filter: unset;
}

.__mp-sel-act .__mp-e-sel-on {
    filter: opacity(1);
    transform: translateY(-4px);

}

.monthly-planning-cell-content {
    min-height: 32px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: default;
    user-select: none;
    max-width: 139px;
    width: 139px;
    box-shadow: var(--box-shadow);
}

.monthly-planning-cell-content-boxed {
    display: flex;
    align-content: center;
    justify-content: space-between;
    min-height: 32px;
    padding: 4px 0;
    max-width: 120px;
    width: 120px;
}

.monthly-planning-cell-content-boxed-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 31px;
    max-width: 120px;
    padding: 0px;
    width: 31px;
}

.monthly-planning-cell-content p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.__breaktime-and-overtime-icons {
    line-height: 15px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 1px; */
}

.__breaktime-count {
    font-size: 12px;
}

.__overtime-count {
    font-size: 12px;
}

.monthly-planning-cell-content-small {
    font-size: 14px;
    width: 33px !important;
    height: 33px !important;
}

.monthly-planning-cell-th-content-small {
    font-size: 14px;
    height: 70px !important;
}

.monthly-planning-cell-overtime {
    background: repeating-linear-gradient(-55deg,
            var(--overtime-primary-color),
            var(--overtime-primary-color) 10px,
            var(--overtime-secondary-color) 10px,
            var(--overtime-secondary-color) 20px);
    color: white;
}

.monthly-planning-cell-undertime {
    background: repeating-linear-gradient(-55deg,
            var(--undertime-primary-color),
            var(--undertime-primary-color) 10px,
            var(--undertime-secondary-color) 10px,
            var(--undertime-secondary-color) 20px);
    color: white;
}

.monthly-planning-cell-oncontextmenu {
    background-color: rgba(187, 99, 19, 0.5) !important;
}

.__event-tab-without-padding .ant-card-body {
    padding: 0 !important;
}


/* @media only screen and (max-width: 900px) {
    .monthly-planning-fixed-users-data  p{
        transform: rotate(-90deg);
        text-align: center;
        max-width: 80px;
        overflow: hidden;
    }
} */


/* 
 * New monthly planning design 
*/

.__mp-event {
    position: relative;
    padding: 4px 8px;
    border-radius: 8px;
    width: 100%;
}

.__mp-cell-dropdown-contextmenu {
    overflow: hidden;
}

.__mp-e-actions {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 4px;
    width: 0%;
    /* bottom: calc(50% - 12.5px); */
    bottom: -16px;
    left: 0px;
    opacity: 0;
    transition: opacity 0.25s ease-in-out 0s, width 0s linear 0s;
    padding-top: 4px;
    padding-bottom: 4px;
}

.__mp-actions-act .__mp-event:hover .__mp-e-actions.__canedit {
    opacity: 1;
    width: 100%;
    transition-delay: 0.2s;
    /* background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(48, 48, 48, 0.11) 30%, transparent 60%); */
}

.__mp-e-action,
.__mp-e-action-cut,
.__mp-e-action-paste {
    border-radius: 50px;
    /* border: 0.5px solid whitesmoke; */
    background-color: var(--btn-background-color);
    color: var(--btn-color);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    transition: all 0.2s ease-in-out, opacity 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.25) 0px -1px 3px 1px;


}

.__mp-e-action-paste {
    background-color: transparent;
    border: none;
    box-shadow: none;
}


.__mp-e-action-paste {
    width: 30px;
    height: 30px;
}

.__mp-e-action-paste-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 0px;
    height: 0px;
}

.__mp-copy-act .ant-table-cell .__mp-paste::after {
    content: '+';
    opacity: 0;
    transition-delay: 0s;
    z-index: 999;
    transform: translate(-50%, -50%) scale(1);
    width: calc(100%);
    height: calc(100%);
    background-color: var(--primary-color);
    position: inherit;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s;
    font-size: 250%;
    font-weight: 100;
    color: white;
}

.__mp-copy-act .ant-table-cell:hover .__mp-paste:not(.disable)::after {
    opacity: 0.93;
    z-index: 999;
    transform: translate(-50%, -50%) scale(1);
}


.__mp-e-action:hover,
.__mp-e-action-cut:hover {
    transition-delay: 0s;
    filter: brightness(92%);
    transform: translateY(-2px);
}

.hvr-reveal:before {
    -webkit-transition-duration: 0.15s;
    transition-duration: 0.15s;
    border-radius: 8px;

}

.hvr-reveal:active:before,
.hvr-reveal:focus:before,
.hvr-reveal:hover:before {
    border-width: 3px;
}

.dark.hvr-reveal:before {
    border-color: #00000066;
}

.white.hvr-reveal:before {
    border-color: #ffffff66;
}

.__mp-main-table .hvr-float-shadow:active:before,
.__mp-main-table .hvr-float-shadow:focus:before,
.__mp-main-table .hvr-float-shadow:hover:before {
    opacity: 0.3 !important;
}

/* .__mp-btn-filter.ant-btn-primary svg {
    fill: white !important;
} */

.__mp-main-table .hvr-float:active,
.__mp-main-table .hvr-float:focus,
.__mp-main-table .hvr-float:hover {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
}

.__mp-main-table .__mp-e-icons {
    line-height: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.__mp-main-table .__mp-e-icon {
    font-size: 12px;
}

.__mp-main-table td.ant-table-cell {
    padding: 10px 9px !important;
    vertical-align: top;

}

.__mp-main-table td.ant-table-cell:hover {
    background-color: #e5e5e5 !important;
}

.__mp-main-table .__mp-cell-hover {
    background-color: var(--background-color) !important;
}

.__mp-main-table .ant-table-row {
    height: 70px !important;
}

.__mp-main-table th {
    background-color: var(--background-color) !important;
    height: 70px !important;
}

.__mp-main-table th:not(:first-child) {
    text-align: center !important;

}

td.ant-table-cell-row-hover {
    background: var(--primary-color-hover) !important;
}

.__mp-main-table .ant-table-tbody>tr.ant-table-row:hover>td,
.__mp-main-table .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: var(--primary-color-hover) !important;
}

.__mp-one-cell .__mp-one-cell-content:not(:last-child) {
    margin-bottom: 10px;
}

.__mp-one-cell-content {
    border-radius: 8px;
}

.__mp-one-cell-content .__mp-event-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    border-radius: 8px;
}

.__mp-one-cell-content .__mp-event-overlay>span:last-child,
.__mp-one-cell-content .__mp-event-overlay>span:first-child {
    display: none;
}

.__mp-one-cell-content.isAdding .__mp-event,
.__mp-one-cell-content.isRemoving .__mp-event {
    background-color: #666 !important;
    color: white !important;
}

.__mp-one-cell-content.isAdding .__mp-event-overlay {
    color: white;
    display: flex;
    background-color: #558b2fa7 !important;
    align-items: center;
    justify-content: center;
}

.__mp-one-cell-content.isAdding .__mp-event-overlay>span:first-child,
.__mp-one-cell-content.isRemoving .__mp-event-overlay>span:last-child {
    display: block;
    margin-top: -10px;
}

.__mp-one-cell-content.isAdding .__mp-event-overlay>span:first-child {
    font-size: 200%;
    font-weight: 200;
}

.__mp-one-cell-content.isRemoving .__mp-event-overlay>span:last-child {
    font-size: 350%;
    font-weight: 100;
}

.__mp-one-cell-content.isRemoving .__mp-event-overlay {
    background-color: #e53935a7 !important;
    color: white;
    display: flex;
}

.__mp-main-table th.__mp-column-weekend,
.__mp-main-table th.__mp-col-we,
.__mp-main-table td.__mp-column-weekend,
.__mp-main-table td.__mp-col-we {
    background-color: #589dd620 !important;
}

.__mp-main-table th.__mp-col-hl,
.__mp-main-table td.__mp-col-hl {
    background-color: #558b2f20 !important;
}

.__mp-main-table th.__mp-column-today,
.__mp-main-table th.__mp-col-today,
.__mp-main-table td.__mp-column-today,
.__mp-main-table td.__mp-col-today {
    background-color: #fbc02d20 !important;
}

.__mp-main-table th.__mp-column-today {
    font-weight: bold;
}

.__mp-main-table th.__mp-column-public-holiday,
.__mp-main-table td.__mp-column-public-holiday {
    background-color: #558b2f20 !important;
}

.__mp-main-table th.__mp-column-users-stats {
    background-color: var(--primary-color) !important;
}

.__mp-main-table th.__mp-column-blocked,
.__mp-main-table td.__mp-column-blocked {
    background-color: #e5393520 !important;

}

.__mp-main-table td,
.__mp-main-table th {
    background-color: white;
    border-right: 1px solid rgb(198, 198, 198);
}

.__mp-main-table .ant-table-cell-fix-left-last {
    border-right: 1px solid rgb(198, 198, 198);
}

.__mp-main-table .ant-table-cell-fix-right-first {
    border-left: 1px solid rgb(198, 198, 198);
}

.__mp-main-table p.__mp-column-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 190px;
}

.__mp-main-table p.__mp-column-name-desc {
    font-size: 70%;
    font-style: italic;
}

.__mp-main {
    display: flex;
    gap: 15px;
    transition: all 0.1s;
}

.__mp-main * {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

.__mp-month-picker.ant-picker-large .ant-picker-input input {
    text-transform: capitalize;
    font-size: 20px;
}

.__mp-month-picker.ant-picker-large {
    padding-left: 0px;
    padding-right: 0px;
}

/* .__mp-main.no-sider {
    gap: 0px
} */


.__mp-main-table-container {
    flex: 1;
    overflow-x: auto;
}

.__mp-main-siders {
    transition: width 0.1s ease-in-out, opacity 0.05s ease-in-out, border 0.1s;
    /* transition-delay: 0.5s border; */
    width: 400px;
    min-width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #c6c6c6;
    padding: 0px 8px 0px 8px;
    overflow: hidden;
    position: relative;
}

@media (max-width: 796px) {
    .__mp-main {
        gap: 0px;
    }

    .__mp-main-siders.sider {
        width: 100%;
        min-width: 100%;
    }
}

.__mp-main-small-width {
    width: 280px;
}

.__mp-main-siders-content {
    max-height: calc(100vh - 256px);
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
}

.__mp-sider-title {
    margin-bottom: 0px;
    font-size: 120%;
    font-weight: bold;
}

.__mp-main-siders.no-sider {
    width: 0px;
    min-width: 0px;
    padding: 15px 0px;
    border: 0px;
    overflow-y: hidden;
    opacity: 0;
}


.__mp-main-table {
    outline: 0px solid var(--primary-color);
    border-radius: 12px;
    transition: outline .2s;
    overflow: hidden;
}

.__mp-main-table.__mp-sel-act,
.__mp-main-table.__mp-copy-act {
    outline: 3px solid var(--primary-color);
}

.__mp-paste {
    display: none;
    pointer-events: none;
}

.__mp-fill-td,
.__mp-copy-act .__mp-paste {
    pointer-events: all;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

.__mp-fill-td.disable {
    cursor: not-allowed;
}

.__mp-cell-blocked {
    background-color: #e5393520 !important;
}

.__mp-cell-partially {
    background-color: #fbc12d4b !important;
}


@media only screen and (min-width: 901px) {
    .__mp-month-picker.ant-picker-large {
        width: 100px !important;
    }
}

@media only screen and (max-width: 900px) {
    .__mp-month-picker.ant-picker-large .ant-picker-input input {
        font-size: 18px;
    }

    .__mp-month-picker.ant-picker-large {
        width: 70px !important;
    }
}

.__e-icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: unset;
}

.__e-icons.wrap {
    flex-wrap: wrap
}

.__flex-column {
    display: flex;
    flex-direction: column;
}

.__e-title {
    text-overflow: ellipsis;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    margin-top: -3px
}

.__e-times {
    font-size: 12px
}

.__mp-u {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding-right: 20px;
}

.__mp-u>.__mp-u-info>div:nth-child(2) {
    font-size: 70%;
    font-style: italic;
}

.__mp-u-info {
    overflow: hidden;
}

.__mp-u-name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.__mp-toolbar {
    opacity: 0;
    pointer-events: none;
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 14px;
    min-width: 400px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    transition: all .22s;

    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.__mp-toolbar.show {
    bottom: 8px;
    pointer-events: all;
    opacity: 1;
}

.__mp-toolbar>div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;

}

.__mp-toolbar .quit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.__mp-topbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.__mp-topbar>.ant-space-item>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

/* .planning-card .card-header .title {
    width: 100%;
} */

.__mp-user-total {
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.__mp-day-total {
    font-weight: normal;
    font-size: 95%;
}

.ant-table-thead>tr>th {
    padding: 6px !important;
}

.overtime-tag {
    border-radius: 20px;
    padding: 1px 7px;
    font-size: 90%;
}

.overtime-tag.pos {
    background-color: rgba(0, 140, 255, 0.217);
    border: 1px solid rgba(0, 140, 255, 0.642);
}


.overtime-tag.neg {
    background-color: rgba(255, 0, 0, 0.217);
    border: 1px solid rgb(255, 0, 0);
}

.counter-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.filter-input {
    display: flex;
    gap: 10px;
}

.filter-group .title {
    font-size: 16px;
    margin: 3px 10px;
}

.contracts-data {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contract-data .contract-title {
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.contract-data {
    margin-top: 6px;
    margin-bottom: 6px;
}

.contract-title .contract-ccnt {
    margin-left: auto;
}

.reset-filters-button {
    position: absolute;
    top: 15px;
    right: 12px;
}

.confirmed-filter {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
    flex: 1;
}

.confirmed-filter>div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
}

.main-title {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 120%;
}

.main-title:first-child {
    margin-top: 10px;
}


/** Planning V2 user **/
._mp-user-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 6px;
}

._mp-user-container-link {
    display: flex;
    flex: 1;
    overflow: hidden;
}

._mp-user-avatar-container {
    padding-right: 6px;
    padding-top: 8px;
}

._mp-user-avatar {
    background-color: var(--primary-color);
}

._mp-user-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

._mp-user-name {
    padding-top: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

._mp-user-events {
    font-size: 70%;
    font-style: italic;
}

._mp-user-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 25px;
    gap: 7px;
}

._mp-user-actions-icon {
    width: 17px;
    height: 17px;
    padding: 4px;
}

._mp-user-contract-popover-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
}