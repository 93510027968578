/* Breadcrumb */

.breadcrumb-content {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0px 20px;
    overflow: hidden;
    white-space: nowrap;
}

.breadcrumb-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #707070;
}

.breadcrumb-title:last-child {
    color: var(--dark-color);
}

.breadcrumb-title:hover {
    color: var(--secondary-color);
}

.breadcrumb-slash {
    margin: 0px 10px;
}


@media only screen and (max-width: 900px) {
    .breadcrumb-content {
        margin: 0px;
    }    
}