.datepicker-with-actions-container {
    display: flex;
    background-color: color-mix(in sRGB, var(--btn-background-color) 50%, transparent);
    border-radius: var(--border-radius);
}

.datepicker-container {
    transition-duration: 140ms;
    width: 156px;
    transition-property: border-radius, border;
    border: 1px solid transparent;
    border-radius: 0px;
}

.datepicker-container .datepicker-container-picker input {
    cursor: pointer;
    text-align: center;
}

.datepicker-container:hover {
    border: 1px dashed var(--primary-color);
    border-radius: var(--border-radius);
}

/* .datepicker-container-popup .ant-picker-body .ant-picker-content { */
.datepicker-container-popup .ant-picker-body {
    height: 230px !important;
}

.datepicker-container-popup .ant-picker-month-panel>.ant-picker-body>.ant-picker-content,
.datepicker-container-popup .ant-picker-year-panel>.ant-picker-body>.ant-picker-content {
    height: 230px !important;
}

/* .datepicker-container-popup .ant-picker-panel table.ant-picker-content {
    height: 230px !important;

} */

/* .ant-picker-panel-container .ant-picker-panel .ant-picker-content, .ant-picker-panel-container .ant-picker-panel table */

.datepicker-container-popup .ant-picker-footer-extra {
    padding: 5px 0;
}