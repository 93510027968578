.container-layout {
    min-width: calc(100% - 300px);
    min-height: 100%;
    transition: all 0.2s ease-in-out 0s;
}

.container-header {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    padding: 0px 20px !important;
}


.container-actions {
    position: absolute;
    right: 20px;
}

.container-actions-item {
    margin: 0px 5px;
}

.container-actions-item:last-child {
    margin-right: 0px;
}

.container-content-background {
    background-color: var(--background-color);
    /* Attention !! */
    /* overflow-x: scroll; */
    position: relative;
    padding: 20px;
}


.container-content-background.background-white {
    background-color: #ffffff;
}


.container-content-background.background-white .card-parent:first-child {
    padding: 0;
}

.__new-container {
    padding: 10px 15px 15px 15px;

}

.__new-container-filters {
    padding: 10px 15px 15px 15px;

}

.container-content-background-dashboard {
    overflow-y: hidden !important;
}

.container-footer {
    background-color: var(--background-color) !important;
    color: var(--dark-color) !important;
    text-align: center;
    border-top: 1px solid #a5a5a5;
}

.container-alert {
    position: fixed;
    bottom: 20px;
    animation: alert-enter-leave 6s forwards;
    max-width: 50%;
    z-index: 10000;
}


.__container-tabs-main.nofilters {
    gap: 0px
}

.__container-tabs-main-table-container {
    flex: 1;
    overflow-x: auto;
}

#container-tabs-content-wrapper,
.container-tabs-spinner-content>.ant-spin-container {
    /* display: flex; */
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
}

#container-tabs-content-wrapper> :first-child,
.container-tabs-spinner-content>.ant-spin-container> :first-child {
    width: 100%;
}

.__container-tabs-main-filters {
    transition: width 0.1s ease-in-out, padding 0.1s ease-in-out, opacity 0.05s ease-in-out, border 0.1s;
    /* transition-delay: 0.5s border; */
    width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    padding: 15px;
    overflow: hidden;
    border: var(--border)
}

.__container-tabs-main-small-width {
    width: 280px;
}

.__container-tabs-main-filters-content {
    max-height: calc(100vh - 285px);
    overflow: auto;
    overflow-x: hidden;
}

.__container-tabs-main-filters.nofilters {
    width: 0px;
    padding: 15px 0px;
    border: 0px;
    overflow-y: hidden;
    opacity: 0;
}

.__container-tabs-main {
    display: flex;
    gap: 10px;
    transition: all 0.1s;
}

@keyframes alert-enter-leave {
    0% {
        left: -1000px
    }

    10% {
        left: 270px
    }

    90% {
        left: 270px
    }

    100% {
        left: -1000px;
    }
}

@media only screen and (max-width: 900px) {

    .container-layout {
        min-width: 100%;
        margin-left: 0px;
    }

    .container-header {
        padding: 0px 10px !important;
        justify-content: space-between;
    }

    .container-actions {
        right: 70px;
    }

    .container-footer {
        padding: 12px;
    }

    .container-alert {
        position: fixed;
        bottom: 20px;
        animation: alert-enter-leave-sp 6s forwards;
        max-width: 80%;
    }

    .container-content-background {
        padding: 20px 10px;
    }

    .__new-container {
        padding: 0px;

    }

    @keyframes alert-enter-leave-sp {
        0% {
            left: -1000px
        }

        10% {
            left: 20px
        }

        90% {
            left: 20px
        }

        100% {
            left: -1000px;
        }
    }
}