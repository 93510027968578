/* TeamManagement */

.team-parent {
    height: 100%;
}

.team-card {
    width: 100%;
    height: 100%;
}

.team-loading-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.team-user-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.team-user-span {
    display: inline-flex;
    margin-bottom: 10px;
}

.team-user-inputfield {
    width: 300px;
    margin-Right: 10px;
}

.team-user-search-switch-p-wrapper {
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 800px) {
    .team-user-span {
        display: inherit;
        margin-bottom: 10px;
    }

    .team-user-search-switch-p-wrapper {
        margin-top: 5px;
    }
}


/* CreateUser */

.team-create-user-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-create-user-speed-dial {
    position: absolute !important;
    top: 15px;
    right: 30px;
    z-index: 4;
}

.team-create-user-password {
    position: absolute;
    right: -2px;
    bottom: 1px;
    background-color: var(--background-color);
}

.team-create-user-password svg {
    height: 10px;
    width: 10px;
}

.team-create-user-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.team-create-user-card {
    width: 45%;
    margin: 10px;
}

.__user-contract-list-item {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.__user-contract-list-item-cursor {
    cursor: pointer;
}

.__user-contract-list-item-selected {
    background-color: #faf1e1;
}

.__user-contract-list-item:hover {
    background-color: #FAFAFA;
}

.user-contract-card {
    width: 50%;
    margin: 10px 10px 30px 10px;
}

.team-create-user-body {
    display: flex;
    flex-direction: column;
}

.team-create-user-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.team-create-user-inputs {
    width: 220px !important;
    min-width: 220px !important;
}

.team-create-user-inputs-large {
    width: 300px !important;
    min-width: 300px !important;
}

.select-width {
    width: 150px !important;
    min-width: 150px !important;

}


.team-create-user-inputs input,
.team-create-user-inputs .ant-select-selection {
    background-color: var(--light-color);
}

.__monthly-hours-edit-input {
    width: 80px !important;
    min-width: 80px !important;
}

.team-create-user-educations-divider {
    height: 1px;
    background-color: #a8a8a8;
    width: 100%;
    margin: 10px 0px 20px 0px;
}

.team-create-user-create-button {
    max-width: 150px;
    animation: expand-appear 1s forwards;
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

/* EditUser */

.team-edit-user-general-left {
    display: flex;
    flex-direction: column;
}

.team-edit-user-general-right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-edit-user-general-right p {
    margin-top: 10px;
}

.team-edit-user-item {
    display: flex;
    align-items: center;
    line-height: 40px;
}

.days-off-by-type {
    list-style: circle;
}

.team-edit-user-item p {
    margin-left: 10px;
}

.team-edit-user-item.gray {
    color: #838383;
}

.team-edit-user-list {
    list-style: none;
    padding-left: 30px;
    line-height: 30px;
}

.team-edit-user-list-title {
    font-size: 18px;
    margin: 10px 0px;
}

.team-edit-user-list-element::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    color: var(--secondary-color);
}

.team-edit-user-bio {
    line-height: normal;
    align-items: flex-start;
    margin: 10px 0px;
}

.team-edit-user-about {
    display: flex;
    flex-wrap: wrap;
}

.team-edit-user-about div {
    margin-right: 40px;
}

.team-edit-user-education-title {
    margin-top: 10px;
    font-size: 18px;
}

.team-edit-user-education {
    margin-left: 20px;
}

/* UserPeriods */

.team-periods-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team-periods-card .ant-table-tbody>tr {
    animation: fade-out 1s reverse;
}

.team-periods-card {
    margin: 20px;
    width: 100%;
    height: fit-content;
}

.team-periods-group-name {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    cursor: pointer;
}

.team-periods-group-name p {
    font-size: 18px;
    margin-left: 8px;
}

.team-periods-group-list-item,
.team-periods-list-item {
    display: flex;
    width: 100%;
}

.team-periods-group-list-item p,
.team-periods-list-item p {
    width: 33%;
    font-weight: normal;
}

.team-periods-group-list-item {
    padding-right: 156px;
}

.team-periods-item-content {
    margin: 0px 10px;
}

.team-periods-item:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.team-periods-item:hover {
    color: var(--primary-color);
    width: calc(100% + 4px);
    box-shadow: var(--box-shadow);
}

.team-periods-day-modal {
    margin: 10px 0px;
}

.team-periods-day-modal-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.team-periods-modal-input {
    width: 200px ! important;
}

/* Exclusion */

.team-exclusions-period {
    display: flex;
    align-items: center;
}

.team-exclusions-select {
    margin-left: 40px;
    width: 400px !important;
}

.team-exclusions-overlap-group {
    width: fit-content;
    font-size: 20px;
    margin: 10px 0px;
    cursor: pointer;
}

.team-exclusions-overlap-event {
    display: flex;
    width: fit-content;
    cursor: pointer;
    align-items: center;
    line-height: 30px;
}

.team-exclusions-overlap-event-dot {
    font-size: 35px;
    margin-right: 5px;
    padding-bottom: 5px;
}

/* CreateGroup */

.team-create-group-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-create-group-table-card {
    margin: 20px 0px;
    width: 100%;
}

/* GroupDetails */

.team-group-details-button {
    margin: 20px 20px 10px 20px;
    max-width: 150px;
    animation: expand-appear 1s forwards;
}

.team-group-details-input {
    margin: 10px 10px 10px 0px;
    width: 300px;
}

.team-group-details-tables .ant-table-wrapper {
    width: 100%;
}

.team-edit-group-actions {
    position: absolute;
    top: 0px;
    right: 20px;
}

.team-edit-group-name {
    font-size: 18px;
}

.__group-member-transfert .ant-transfer-list:first-child {
    border-style: dashed;
}

.__group-member-transfert .ant-transfer-list:last-child {
    border: 2px var(--primary-color) solid !important;
}

.__single-line {
    display: flex;
    align-items: center;
}

.__single-line .__single-line-element {
    margin-right: 5px;

}

.__single-line .__single-line-element:last-child {
    margin-right: 0px;
}


/* User vacations */

.team-user-vacations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    height: 55px;
}


.team-user-vacations-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.user-contract-content {
    display: flex;
    padding-bottom: 20px;
}

.team-user-vacations-card {
    width: 49%;
    margin-bottom: 20px;
}

.content-card-max-height {
    max-height: 500px;
    overflow: auto;
}

.team-user-vacations-card:first-child {
    margin-right: 10px;
}

.team-user-vacations-modal-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}

.team-user-vacations-modal-section:first-child {
    margin-top: 0px;
}

.team-user-vacations-modal-section:last-child {
    margin-bottom: 0px;
}

.team-user-vacations-modal-field {
    width: 370px !important;
}

.team-user-vacations-modal-duration {
    margin: 0px 10px !important;
    width: 70px !important;
}

.__availability-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

/* .__form-standard .ant-form-item-control {
    text-align: right;
}
.__form-standard-2 .ant-form-item-control {
    text-align: center;
} */

.__content_animated {
    max-height: 0px;
    overflow: hidden;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
}

.__content_animated.__show {
    max-height: 300px;
}

.__fullwidth-file-uploader {
    width: 100%;
}

@media only screen and (max-width: 900px) {

    /* TeamManagement */

    .team-parent .ant-tabs-top-bar {
        padding-bottom: 30px;
    }

    .team-parent .team-header-button {
        top: 20px;
    }

    /* CreateUser */

    .team-create-user-cards {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .team-create-user-card {
        width: 100%;
        margin: 20px 0px;
    }

    .team-create-user-card:first-child {
        margin-top: 0px;
    }

    .team-create-user-group:not(.team-create-user-group-inline) {
        flex-direction: column;
        align-items: flex-start;
    }

    .team-create-user-inputs {
        margin-top: 10px;
        width: 100%;
    }

    .team-create-user-create-button {
        margin: 20px;
        max-width: calc(100% - 40px);
    }

    .team-group-details-input {
        width: auto;
    }

    /* EditUser */

    .team-edit-user-general-right {
        margin-top: 20px;
    }

    /* CreateGroup */

    .team-create-group-table-card:first-child {
        margin-top: 0px;
    }

    /* Exclusions */

    .team-exclusions-period {
        flex-direction: column;
        align-items: flex-start;
    }

    .team-exclusions-select {
        margin-left: 0px;
        margin-top: 20px;
        width: 100% !important;
    }

    /* Vacations */

    .team-user-vacations-content {
        flex-direction: column;
    }

    .team-user-vacations-card {
        width: 100%;
    }
}