.header-container{
    height: 15vh;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
}

.content-container{
    min-height: 80vh;
    text-align: center;
    padding: 10vh;  
}

.content-link {
    margin-top: 4vh;
}

.content-text{
    margin-top: 4vh;
}

.content-title{
    font-size: xx-large;
}

.footer-container{
    min-height: 5vh;
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: center;
    border-top: 1px solid #b1b1b1;;
}

.sunkhro-logo {
    max-width: 80%;
    max-height: 80%;
    margin-top: 1rem;
}

@media screen and (max-width: 450px) {
    .content-container{
        min-height: 80vh;
        text-align: center;
        padding: 7vh;
    }

    .content-title{
        font-size: large;
        font-weight: bold;
    }

    .content-link {
        margin-bottom: 4vh;
    }

    .header-container{
        max-height: 10vh;
        text-align: center;
    }

    .sunkhro-logo {
        max-width: 80%;
        max-height: 100%;
        margin-bottom: 1rem;
    }

    
 }

@media screen and (max-width: 600px) {
    .header-container{
        height: 15vh;
        text-align: center;
    }
    .sunkhro-logo {
        max-width: 80%;
        max-height: 100%;
        margin-bottom: 1rem;
    }    
}