:root {
    --img-top: 65px;
    --title-relative-top: 125px;
    --code-relative-top: 35px;

    --title-hover-top: 30px;

    --description-bottom: 50px;
    --buttons-bottom: 20px;
}

.__modern-card {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    background-color: var(--background-card-color);
    border-radius: var(--border-radius);
    position: relative;
    overflow: hidden;
    padding: 30px;
    cursor: pointer;
}

.__modern-card::before {
    content: "";
    width: 100%;
    height: 120px;
    position: absolute;
    top: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* background: linear-gradient(40deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%); */
    background: linear-gradient(40deg, #C09F50 0%, #c09e5083 50%, #C09F50dd 100%);
    background-color: var(--modern-card-color);
    transition: all 0.4s ease;
}

.__modern-card * {
    z-index: 1;
}

.__modern-card-image {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--background-card-color);
    position: absolute;
    top: var(--img-top);
    left: 50%;
    padding: 6px;
    transform: translateX(-50%);
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

}

.__modern-card-image img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.__modern-card-info .__modern-card-title {
    font-weight: 600;
    font-size: 24px;
    color: #161A42;
    position: absolute;
    top: calc(var(--img-top) + var(--title-relative-top));
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 50%;
    transform: translateX(50%);
    transition: all 0.4s ease;
}

.__modern-card-info .__modern-card-code {
    color: #161A42aa;
    position: absolute;
    top: calc(var(--img-top) + var(--title-relative-top) + var(--code-relative-top));
    transform: translateY(-50%);
    right: 50%;
    transform: translateX(50%);
    transition: all 0.4s ease;
}

.__modern-card-description {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    color: var(--light-primary-color);
    position: absolute;
    transition: bottom 0.4s ease-in-out, opacity 0.5s ease-in-out;
    bottom: -40px;
    opacity: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-style: italic;
    font-size: 90%;
}

.__modern-card-description>p {
    font-style: normal;
    font-size: 110%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.__modern-card-description div.__modern-card-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.__modern-card-buttons {
    position: absolute;
    transition: bottom 0.4s ease-in-out, opacity 0.5s ease-in-out;
    bottom: -86px;
    opacity: 0;
}

.__modern-card:hover::before {
    width: calc(100% - 20px);
    height: calc(300px - 20px);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin: 10px;
    /* transform: scale(0.95); */
}

.__modern-card:hover .__modern-card-info .__modern-card-title {
    top: var(--title-hover-top);
    transform: translateY(0);
    right: 20px;
    transform: translateX(0);
    padding-left: 0;
    padding-right: 0;
    max-width: calc(100% - 180px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.__modern-card:hover .__modern-card-info .__modern-card-code {
    top: calc(var(--title-hover-top) + var(--code-relative-top));
    transform: translateY(0);
    right: 20px;
    transform: translateX(0);

}

.__modern-card:hover .__modern-card-info .__modern-card-code,
.__modern-card:hover .__modern-card-info .__modern-card-title,
.__modern-card:hover .__modern-card-description {
    color: var(--light-primary-color);
}

.__modern-card:hover .__modern-card-image {
    transform: scale(1.7);
    top: -10px;
    left: 10px;

}

.__modern-card:hover .__modern-card-description {
    bottom: var(--description-bottom);
    opacity: 1;

}

.__modern-card:hover .__modern-card-buttons {
    bottom: var(--buttons-bottom);
    opacity: 1;
}