/* Newsfeed (Add) */

.no-padding .ant-comment-inner{
    padding: 0;
}
.newsfeed-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.newsfeed-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.newsfeed-title {
    margin: 5px 0px 30px 80px !important;
}

.newsfeed-left {
    min-height: 100%;
}

.newsfeed-right {
    max-width: 45%;
    width: 45%;
}

.newsfeed-input {
    margin: 10px 0px;
}

.newsfeed-image-uploader {
    margin-top: 20px;
    height: 150px;
    width: 100%;
}

.newsfeed-submit {
    max-width: 200px;
    width: 200px;
}

/* NewsList */

.newslist-content {
    position: relative;
}

.newslist-vertical-line{
    width: 4px;
    height: calc(100% - 15px);
    background-color: rgba(140, 140, 140, 0.3);
    position: absolute;
    top: 15px;
    left: 14px;
}

.newslist-add-button {
    position: absolute;
    left: calc(60% + 80px);
    z-index: 2;
}

.newslist-empty {
    width: calc(60% + 100px);
}

.newslist-skeleton {
    padding-right: 10px;
    max-width: 60%;
}

.newsfeed-delete {
    animation: fade-out 2s;
}

/* NewsCard */

.newscard-parent {
    display: flex;
    margin: 0px 0px 20px 0px;
}

.newscard-avatar-parent {
    position: relative;
    margin-right: 15px;
    padding-top: 14px;
}

.newscard-avatar {
    z-index: 2;
}

.newscard-avatar-shadow {
    background-color: transparent;
    border-radius: var(--border-radius);
    height: 32px;
    width: 32px;
    z-index: 1;
    transform: translateY(-32px);
    box-shadow: var(--box-shadow);
}

.newscard-item {
    position: relative;
    background-color: white;
    color: var(--dark-color);
    border-radius: 5px;
    padding: 8px 5px 15px 15px;
    width: 60%;
    box-shadow: var(--box-shadow);
    display: flex;
}

.newscard-item:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 10px;
    left: -15px;
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-right: 20px solid var(--light-color); 
}

.newscard-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newscard-item-header-contacts {
    width: calc(100% - 120px);
    display: flex;
    align-items: center;
}

.newscard-item-header-contacts svg {
    margin: 0px 20px;
}

.newscard-item-header-creator {
    color: var(--light-color);
    border-radius: var(--border-radius);
    height: 30px;
    padding: 0px 6px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.newscard-item-header-creator-span{
    white-space: nowrap;
    overflow: hidden;  
    text-overflow: ellipsis;
}

.newscard-item-date {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
}

.newscard-item-body {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 0px;
    word-break: break-word;
}

.newscard-item-title {
    font-size: 17px;
    margin: 10px 0px;
    color: var(--dark-color);
}

.newscard-item-translation {
    display: flex;
    margin: 10px 0px;
}

.newscard-item-translation:first-child {
    margin-top: 0px;
}

.newscard-item-translation:last-child {
    margin-bottom: 0px;
}

.newscard-item-image {
    margin-bottom: 10px;
    border-radius: 5px;
    object-fit: contain;
    max-width: 100%;
    max-height: 300px;
    width: auto;
    height: auto;
}

.newscard-menu-item svg {
    width: 16px;
    height: 16px;
}

.newscard-item-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.newscard-item-confirmed-container {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.newscard-item-confirmed {
    font-size: 20px;
}


/* Smartphone */

@media only screen and (max-width: 1300px) {

    /* NewsFeed (Add) */

    .newsfeed-parent {
        padding: 0px;
    }
    
    .newsfeed-content {
        flex-direction: column;
    }
    
    .newsfeed-right {
        width: 100%;
        max-width: 100%;
    }

    .newsfeed-title {
        margin-top: 20px !important;
    }

    /* NewsCard */

    .newscard-item {
        width: calc(100% - 50px);
    }

    /* .newscard-item-header-contacts {
        flex-direction: column;
        align-items: flex-start;
    }

    .newscard-item-header-contacts svg {
        margin: 10px 0px 10px 30px;
        transform: rotate(90deg);
    } */

    .newscard-item-header-creator {
        overflow: hidden;
        white-space: pre;
    }

    .newscard-item-date {
        min-width: 90px;
    }

    .newscard-item-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0px 10px 0px 0px;
    }
}