.__work-control-summary-date-clickable-text {
    transition: all .3s;
}

.__work-control-summary-date-clickable-text.active {
    color: green;
}

.__work-control-summary-date-clickable-text:hover:not(.active) {
    cursor: pointer;
    text-decoration: underline;
    /* transform: scale(0.98); */
}