.import-table {
  min-width: 100%;
  cursor: crosshair;
  border-collapse: collapse;
  border-spacing: 10px;
  border-radius: 10px;
}

.div-table-header {
  border-radius: 10px 10px 0px 0px;
  background: rgb(250, 250, 250);
  height: 50px;
}

.div-table-header .ant-select {
  width: 100%;
  cursor: pointer;
  background: transparent !important;
  height: 100%;
}

.div-table-header .ant-select-selector {
  border-radius: 0px !important;
  border: 1px solid transparent !important;
  background: transparent !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.div-table-header,
.div-table-content.div-table-row {
  border-bottom: 1px solid #b1b1b1;
}

.div-table {
  min-width: 100%;
  cursor: crosshair;
  border-collapse: collapse;
  overflow: scroll;
}

.div-table-row {
  display: grid;
  user-select: none !important;
  min-width: fit-content;
}

.div-table-cell {
  min-width: 200px;
  width: 200px;
}

.div-table-content .div-table-cell {
  cursor: crosshair;
  float: left;
  height: 40px !important;
  display: flex;
  padding-left: 16px;
  align-items: center;
  /* transition: all .2s; */
  border: 1px solid transparent;
  overflow-x: auto;
}

.div-table-content .div-table-cell:has(.ant-select-selector) {
  padding-left: 0px;
}

.div-table-content .div-table-cell .ant-select-selector {
  border: 1px solid transparent !important;
  height: 100%;
  width: 100%;
}

.div-table-content .div-table-cell:hover {
  background-color: rgba(var(--primary-color-rgb), 0.3);
}

.div-table-content .div-table-cell.selected,
.import-table-selectable-cell.isSelected .div-table-cell,
.import-table-selectable-cell.isAdding .div-table-cell {
  background-color: rgba(var(--primary-color-rgb), 0.2);
  border-color: rgba(var(--primary-color-rgb), 1);
  border-style: solid;
  border-width: 1px;
}

.div-table-cell.line-index {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  display: flex;
  padding: 0;
  justify-content: center;
}

.div-table-header {
  border: 1px solid #b1b1b1;
}

.div-table-content .div-table-cell,
.div-table-header .div-table-cell {
  border-left: 1px dotted #b1b1b1;
  border-right: 1px dotted #b1b1b1;
}

.div-table-header .div-table-cell:first-child {
  border: none;
}

.div-table-content .div-table-cell:first-child {
  border-left: 1px solid #b1b1b1;
}

.div-table-content .div-table-cell:last-child {
  border-right: 1px solid #b1b1b1;
}

.div-table-content .div-table-cell,
.div-table-content .div-table-cell .ant-select-selector {
  border-radius: 0px !important;
}

.div-table-cell.warn {
  background-color: #ffebc1;
}

.div-table-cell.warn:hover {
  background-color: #ffe2a4;
}

.div-table-content .div-table-cell.warn.selected,
.import-table-selectable-cell.isSelected .div-table-cell.warn,
.import-table-selectable-cell.isAdding .div-table-cell.warn {
  background-color: #ffe2a4;
}

.div-table-cell.error {
  background-color: #ffeeee;
}

.div-table-cell.error:hover {
  background-color: #ffdddc;
}

.div-table-content .div-table-cell.error.selected,
.import-table-selectable-cell.isSelected .div-table-cell.error,
.import-table-selectable-cell.isAdding .div-table-cell.error {
  background-color: #ffdddc;
}

.div-table-cell-icon {
  display: flex;
  align-items: center;
  margin-right: 3px;
  width: 20px;
}

.div-table-cell-icon svg {
  cursor: help;
}

.div-table-cell:focus {
  outline: none;
}

.div-table-content:last-child {
  border-radius: 0px 0px 10px 10px;
}

.div-table-content:last-child .div-table-cell:first-child {
  border-radius: 0px 0px 0px 10px;
}

.div-table-content:last-child .div-table-cell:last-child {
  border-radius: 0px 0px 10px 0px;
}

.placeholder {
  color: gray;
  font-style: italic;
}