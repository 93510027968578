.editable-cell {
    position: relative;
}

.editable-row input.ant-input {
    border-radius: 2px !important;
}

.editable-cell-value-wrap {
    padding: 3px 11px;
    border: 1px solid transparent;
    cursor: text;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 3px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}