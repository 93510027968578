.sms-account-details-title{
    font-weight: bold;
}
.sms-list-table-parent .ant-tabs-nav-list{
    margin: auto;
}
.__smssent .ant-collapse-header-text{
    width:100%
}
.__xxsmsxx .ant-drawer-body{
    background: #eeeeee;
}
.__smssent .ant-collapse{
    border-radius: 13px;
}

.__sms .ant-tabs-extra-content{
    text-align: right;
}