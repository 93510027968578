/* Classes */

.expand-appear-animation {
  animation: expand-appear 0.5s;
}

.expand-appear-right-animation {
  animation: expand-appear-right 1s;
}

.appear-from-bottom-animation {
  animation: appear-from-bottom 1s;
}

.appear-from-right-animation {
  animation: appear-from-right 1s;
}

.team-search-div {
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.fade-out-animation {
  animation: fade-out 1s;
}

.fade-in-animation {
  animation: fade-out 1s reverse;
}

.roll-right-disappear-animation {
  animation: roll-right-disappear 1s !important;
}

.shake-animation {
  animation: shake 1s;
}

/* Animations */

@keyframes expand-appear {
  0% {
    width: 0px;
    overflow: hidden;
    white-space: pre;
  }

  100% {
    width: 100%
  }
}

@keyframes expand-appear-right {
  0% {
    padding-left: 80%;
  }

  100% {
    padding-left: 0%;
  }
}

@keyframes appear-from-top {
  0% {
    transform: translateY(-150%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes appear-from-bottom {
  0% {
    transform: translateY(150%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes appear-from-right {
  0% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes appear-from-left {
  0% {
    transform: translateY(-150%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes grow-appear {
  0% {
    transform: scale(0);
  }

  70% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoom-in-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.01);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes speed-dial {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes tilt-right {
  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-15deg);
  }

  75% {
    transform: rotate(10deg);
  }

  0%,
  100% {
    transition: rotate(0deg);
  }
}

@keyframes roll-right-disappear {
  100% {
    opacity: 1;
    transform: translateX(500px) rotateZ(720deg)
  }
}

@keyframes flip-over {
  100% {
    transform: rotateY(180deg)
  }
}

@keyframes rotate-90deg-left {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes planning-drag {
  50% {
    transform: translate3d(-2px, 0, 0);
  }

  100% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes up-down {
  from {
    margin-top: -10px;
  }

  to {
    margin-top: 10px
  }
}

@keyframes grow-shrink {
  from {
    transform: scale(1.2);
  }

  to {
    transform: scale(0.8)
  }
}