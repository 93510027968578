.reset-password-title {
    margin: 10px 0px;
    font-size: 20px;
}

.reset-password-input {
    margin: 10px 0px;
    padding: 0px 20px;
    width: 100%;
}

.reset-password-submit {
    margin: 10px 0px 20px 0px;
}