/* CRM */

.crm-tab-signature {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.crm-tab-signature-icon {
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    margin-right: 5px;
}

.__table_messagesTab .__table_messagesTab_ExpandedTable .ant-table {
    margin: 0 !important;
    border-radius: var(--border-radius);
}

.__table_messagesTab .__table_messagesTab_ExpandedTable .ant-table th.ant-table-cell:first-child {
    border-top-left-radius: var(--border-radius);
}

.__table_messagesTab .__table_messagesTab_ExpandedTable .ant-table th.ant-table-cell:last-child {
    border-top-right-radius: var(--border-radius);
}

.__table_messagesTab .__table_messagesTab_ExpandedTable .ant-table-row:last-child td.ant-table-cell:first-child {
    border-bottom-left-radius: var(--border-radius) !important;
}

.__table_messagesTab .__table_messagesTab_ExpandedTable .ant-table-row:last-child td.ant-table-cell:last-child {
    border-bottom-right-radius: var(--border-radius) !important;
}

.crm-tab-signature-icon svg {
    height: 22px;
    width: 22px;
}

.__company-product-show {
    min-height: 211px;
    background-color: #fbfbfc;
    border-radius: 5px;
}

.__company-product-show-image {
    padding: 10px;
}

.messages-tab-container .ant-drawer-content,
.messages-tab-container .ant-drawer-mask {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
}

.messages-tab-container .ant-drawer-header-title {
    height: 35px
}

.__company-product-show-image div {
    width: 100%;
    height: 100%;
    max-height: 200px;
    max-width: 200px;
    background-size: cover;
    border-radius: 5px
}

.__company-product-show-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.__company-product-show-content-last-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 5px 5px 5px
}

.__company-product-show-content-last-line button {
    background-color: #2b6fab;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    height: 40px;
    font-size: 16px;
}

.__company-product-show-content-last-line span {
    height: 40px;
}

.__company-product-show-content-title {
    border: 1px dashed transparent;
    /* font-family: "Montserrat"; */
    font-size: 22px;
    color: #2a5c88;
    /* font-weight: 100; */
    margin-bottom: 10px;
}

.__company-product-show-content-title-input input {
    border: none;
    background-color: transparent;
    font-size: 22px;
    color: #2a5c88;
    /* font-weight: 100; */
    /* margin-bottom: 10px; */
    padding: 0;

}

.__create-promotion-title {
    font-weight: bold;
}

.__create-promotion-hr {
    height: 1px;
    background-color: #c6c6c6;
    width: calc(100% + 34px);
    margin-left: -17px;
    margin-right: -17px;
}

.__company-product-show-content-title-input .ant-input:focus,
.__mail-preview-title-input .ant-input:focus {
    box-shadow: none;
    border-color: transparent;
    border-radius: 0px;

}

.__mail-preview-title-input input {
    border: none;
    background-color: transparent;
    font-size: 22px;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
}

.__mail-preview-title-input {
    width: 100%;
}

.__company-product-show-content-description {
    border: 1px dashed transparent;
    text-align: justify;
    font-size: 14px;
    color: #595959;
    width: 100%;
    line-break: auto;
    padding: 0;
}

.__crm-codeDiscount-modal-input {
    width: 100%;
    min-width: 100%;
}

/* .__crm-codeDiscount-modal-input input {
    text-align: right!important;
    padding-right: 30px;

} */

.__company-product-show-content-title>div:first-of-type,
.__company-product-show-content-description>div:first-of-type {
    margin: 5px;
}



.__company-product-show-content-title-b,
.__company-product-show-content-description-b {
    border: 1px dashed #ccccccdd;
    border-radius: 5px;

    animation: blink 1.5s;
    animation-iteration-count: infinite
}

@keyframes blink {
    0% {
        border-color: #ccccccdd
    }

    50% {
        border-color: #cccccc44
    }

    100% {
        border-color: #ccccccdd
    }
}

.__product-edit-modal .ant-modal-close-x {
    line-height: 50px;
}

.__product-edit-modal .ant-modal-close-x .configurations-width-100 {
    right: 10px;
}

.__create-promotion-select-lang {
    width: 55px
}

.__create-promotion-select-lang .ant-select-selection-item {
    height: 31px;
    display: flex;
}

.__create-promotion-select-lang .ant-select-selection-item img {
    object-fit: contain;
}

.__product-edit-modal .ant-modal-close-x .ant-select-selection-item {
    height: 33px;

}

.crm-tab-signature-icon-colored path,
li:hover>.crm-tab-signature path {
    stroke: var(--primary-color);
    fill: var(--primary-color);
}

.crm-tab-signature-icon-colored .cls-2,
li:hover>.crm-tab-signature .cls-2 {
    fill: none;
}

/* Client tab */

.crm-search {
    display: flex;
}

.crm-search-field {
    width: 300px;
}

/* Promotions tab */

.crm-cards {
    display: flex;
    align-items: baseline;
    min-width: 800px;
}

.crm-card {
    width: 60%;
    margin-bottom: 20px;
}

.crm-card-preview {
    width: 40%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.crm-loyalty-image {
    height: 150px;
    width: 150px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.crm-section {
    display: flex;
    align-items: center;
    line-height: 35px;
}

.crm-section-title {
    margin: 20px 0px;
}

.crm-section-icon {
    margin-right: 10px;
}

.crm-select {
    width: 300px;
    margin-right: 20px;
}

.crm-inputfield {
    width: 400px;
    margin: 20px 0px;
}

.crm-textarea {
    margin: 20px 0px;
}

.crm-button {
    margin: 40px auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 200px;
    animation: expand-appear 1s forwards;
}

/* Voucher */

.crm-vouhcer-modal-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
}

.crm-voucher-modal-input {
    width: 300px;
}

.crm-voucher-modal-input-number {
    width: 100px;
}

/* Signature */

.crm-signature-card-icon {
    height: 34px;
    width: 34px;
    filter: brightness(100);
}

/* Mail Preview */

.crm-promotion-create-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

/* Take all height available to table scrool-y */
.__crm-clients-table .ant-table-body {
    max-height: calc(100vh - 495px) !important;

}

@media only screen and (max-width: 900px) {
    .crm-search-field {
        width: 100%;
    }
}

.crm-mission-error {
    padding: 6px;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
}