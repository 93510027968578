.check-mobile {
    width: 200px;
    margin: auto;
}

.no-mobile-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-weight: bold;
    margin-top: 20px;
}