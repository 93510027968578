.__markdown-help h1,
.__markdown-help h2,
.__markdown-help h3,
.__markdown-help h4 {
    margin-top: 10px;

}

.__markdown-help h1 {
    color: var(--primary-color);
    font-size: 24px;
    
}

.__markdown-help h2 {
    color: var(--primary-color);
    font-size: 22px;
    border-top: 1px solid #ccc;
    margin-top: 40px;
    padding-top: 10px;
    
}

.__markdown-help h2:first-of-type {
    border-top: none;
    margin-top: 20px;
    
}

.__markdown-help h3 {
    color: var(--dark-color);
    font-size: 18px;
    text-decoration: underline;
}

.__markdown-help p, 
.__markdown-help li,
.__markdown-help ul {
    font-size: 16px;

}