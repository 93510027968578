.__course-map-content,
.__filter-map-content,
.__filter-map-content1 {
    position: relative;
    overflow: hidden;
    text-align: center;
    background: #fafafa;
    border: 1px solid #ebedf0;
    border-radius: 2px;
    min-height: 400px;
}

.__course-map-content {
    min-height: 420px;
    height: calc(100vh - 195px);
}

.__leaflet-map-spinner,
.__leaflet-map-spinner .ant-spin-container {
    height: 100% !important;
}

.__leaflet-map-spinner .ant-spin-blur {
    z-index: 1000;

}

.__filter-map-content {
    height: calc(100vh - 129px);
}

.__filter-map-content1 {
    height: 100%;
}

.__drawer_border_top_radius .ant-drawer-content,
.__drawer_border_top_radius .ant-drawer-mask {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.__course-map-poi,
.__course-map-poi-adding {
    width: 40px;
    height: 40px;
    color: var(--primary-color);
    cursor: pointer
}


.__course-map-poi-adding {
    animation: blinker 1.2s linear infinite;
}

.__course-map-poi {
    color: var(--primary-color);
}

.__card-button-space {
    margin-left: 5px;
}

.__poi-actions,
.__staff-type-actions {
    text-align: center !important;
}

td.__poi-actions,
td.__staff-type-actions {
    display: flex;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

@media only screen and (max-width: 900px) {
    .__card-button-space {
        margin-left: 5px;
    }
}