.listing-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.listing-card {
    width: calc(50% - 10px);
}

.listing-filter {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 10px 0px;
}

.listing-add-category-modal {
    display: flex;
    margin: 20px 0px 0px 0px;
}

.listing-add-category-modal-input {
    width: 50%;
    margin-right: 10px;
    margin-left: 10px;
}

.listing-add-subcategory-modal {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 0px 0px;
}

.listing-add-subcategory-modal-section {
    display: flex;
}

.listing-add-category-modal-select {
    margin-left: 10px;
    width: 100%;
}

/* CreateEditListingItemModal */

.listing-modal {
    min-width: 700px !important;
}

.listing-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.listing-modal-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    width: 400px;
}

.listing-modal-section:last-child {
    margin-bottom: 0px;
}

.listing-modal-input {
    width: 250px !important;
}

.image-uploader-full-width {
    width: 100%;
}

.listing-modal-section-title {
    margin-right: 10px;
}

/* ListingCategoryDetails */

.listing-category-subcategory-card {
    width: 100%;
    margin: 40px 0px;
}

.listing-category-subcategory-card:first-child {
    margin-top: 0px;
}

.listing-category-section {
    display: flex;
    align-items: center;
    margin: 20px 0px;
}

.listing-category-link {
    margin-left: 20px;
}

.listing-category-image-container {
    margin-bottom: 10px;
    height: 100%;
    width: 100%;
    max-height: 200px;
}

.listing-category-image {
    max-width: 100%;
    max-height: 200px;
}

.listing-category-image-uploader {
    width: 100%;
    height: 100%;
    max-height: 300px;
}

.listing-category-save-button {
    margin: 20px 0px 10px 0px;
}

@media only screen and (max-width: 900px) {
    .listing-container {
        flex-direction: column;
    }

    .listing-card {
        width: 100%;
    }

    .listing-card:first-child {
        margin-bottom: 40px;
    }

    .listing-modal {
        min-width: 95% !important;
    }

    .listing-modal-section {
        flex-direction: column;
        align-items: flex-start;
        width: auto;
    }

    .listing-modal-input {
        margin: 10px 0px 0px 0px;
    }
}