.__wave-group {
    position: relative;
}

.__wave-group .__input {}

.__wave-group .__input:focus {
    outline: none;
}

.__wave-group .__label {
    z-index: 9999;
    color: #999;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 4px;
    display: flex;
}

.__wave-group .__label-char {
    transition: 0.15s ease all;
    transition-delay: calc(var(--index) * .04s);
    font-size: 16px;
    padding: 0px 0.5px;
}

.__wave-group .__label-char:first-child {
    padding-left: 5px;
}

.__wave-group .__label-char:last-child {
    padding-right: 5px;
}

.__wave-group:has(.__input:focus) label,
.__wave-group:has(.__input:valid) label {
    padding: 0px 5px;
}

.__wave-group:has(.__input:focus) label .__label-char,
.__wave-group:has(.__input:valid) label .__label-char {
    transform: translateY(-15px);
    font-size: 13.5px;
    background-color: white;
    height: 12px;
}