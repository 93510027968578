:root {
    --primary-color: #bea156;
    --primary-color-rgb: 189, 157, 79;
    --primary-color-hover: #fdf6f1;
    --primary-color-light2-rgb: rgb(203, 174, 99);
    --light-primary-color: #fdf6f1;
    --btn-background-color: #e8ebf1;
    --btn-color: #2f373f;
    --primary-btn-background-color: #bea156;
    --primary-btn-color: #e8ebf1;
    --secondary-color: #2f373f;
    --terceary-color: #4a7c66;
    /* --background-color: #e4e4e4; */
    --background-color: #f3f6ff;
    --highlight-background-color: #f7f7f7;
    --background-card-color: white;
    /* #eee*/
    /* --background-color: #102027; */
    --dark-color: #373737;
    --light-color: white;
    --error-color: #ff2626;
    --border-color: #b1b1b1;
    --border-color-aa: #b1b1b1aa;
    --success-color: #51b709;
    --positive-color: #0949b7;
    --overtime-primary-color: #5d126b;
    --overtime-secondary-color: #801093;
    --undertime-primary-color: #740000;
    --undertime-secondary-color: #b10202;
    --box-shadow: none;
    /* --box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    /* --planning-is-period: #f9caa799; */
    /* --planning-is-period: #c3b9b188; */
    --planning-is-period: #c2c2c288;
    --planning-outside-period: rgba(255, 38, 38, 0.2);
    /* --planning-vacations: #bfd6f640; */
    /* --planning-vacations: #f7f7f780; */
    --planning-vacations: #c5e1a560;
    --planning-user-vacations: #3da4ab99;
    --planning-weekend: white;
    /* --planning-weekend-user: #be9b7b44; */
    /* --planning-weekend-user: #ffd3b680; */
    --planning-weekend-user: #d8d8d880;
    /* --today-primary-color: #dfc2aabe; */
    --today-primary-color: #8d8d8d99;
    --th-today-primary-color: #a8a8a8;
    /* --planning-user-1: #F0D5C0; */
    /* --planning-user-2: #F6DBC6; */
    --planning-user-1: #d2cdc8;
    --planning-user-2: #d9d4cf;
    --planning-outside-working-days: rgba(142, 142, 142, 0.25);
    --timeclock-cannot-be-badged: rgb(170, 5, 5);

    --border-radius: 18px;
    --border: 1px solid rgba(0, 0, 0, 0.1);

    --icon-red: #e74c3c;
    --icon-green: #2ecc71;
    --icon-blue: #3498db;
    --icon-gray: #a0a0a0;

    --text-secondary: #8d8d8d99;
}

html.dark-theme {
    --primary-color: #bea156;
    --primary-color-rgb: 189, 157, 79;
    --primary-color-hover: #fdf6f1;
    --light-primary-color: #fdf6f1;
    --btn-background-color: #e8ebf1;
    --btn-color: #2f373f;
    --secondary-color: #2f373f;
    --terceary-color: #4a7c66;
    /* --background-color: #e4e4e4; */
    --background-color: #2f373fd5;
    --background-card-color: white;
    /* #eee*/
    /* --background-color: #102027; */
    --dark-color: #373737;
    --light-color: white;
    --error-color: #ff2626;
    --border-color: #b1b1b1;
    --success-color: #51b709;
    --positive-color: #0949b7;
    --overtime-primary-color: #5d126b;
    --overtime-secondary-color: #801093;
    --undertime-primary-color: #740000;
    --undertime-secondary-color: #b10202;
    --box-shadow: none;
    /* --box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 6px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    /* --planning-is-period: #f9caa799; */
    /* --planning-is-period: #c3b9b188; */
    --planning-is-period: #c2c2c288;
    --planning-outside-period: rgba(255, 38, 38, 0.2);
    /* --planning-vacations: #bfd6f640; */
    /* --planning-vacations: #f7f7f780; */
    --planning-vacations: #c5e1a560;
    --planning-user-vacations: #3da4ab99;
    --planning-weekend: white;
    /* --planning-weekend-user: #be9b7b44; */
    /* --planning-weekend-user: #ffd3b680; */
    --planning-weekend-user: #d8d8d880;
    /* --today-primary-color: #dfc2aabe; */
    --today-primary-color: #8d8d8d99;
    --th-today-primary-color: #a8a8a8;
    /* --planning-user-1: #F0D5C0; */
    /* --planning-user-2: #F6DBC6; */
    --planning-user-1: #d2cdc8;
    --planning-user-2: #d9d4cf;
    --planning-outside-working-days: rgba(142, 142, 142, 0.25);
    --timeclock-cannot-be-badged: rgb(170, 5, 5);

    --border-radius: 18px;
    --border: 1px solid rgba(0, 0, 0, 0.1);
}