.ant-layout-sider {
    z-index: 30 !important;
    transition: all 0.2s ease-in-out;
}

/* .sidemenu-background:before {
    background: #262e37;
    background: url('../images/background.jpg') center center no-repeat, radial-gradient(circle, #22201d91 0%, #22201d 100%);
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 350px;
    content: '';
    opacity: 0.5;
} */

.sidemenu-collapse {
    position: absolute;
    top: 0px;
    right: -11px;
    cursor: pointer;
    font-size: 25px;

}

.sidemenu-collapse-open,
.sidemenu-collapse-close {
    opacity: 1;
    transition: opacity 0.2s;
}

.sidemenu-collapse-open .anticon,
.sidemenu-collapse-close .anticon {
    padding: 3px;
    border: 2px solid #ffffff91;

}

.sidemenu-collapse-open>span,
.sidemenu-collapse-close>span {
    background-color: var(--secondary-color);
    color: #ffffffa6;
    border-radius: 50px;
}

.sidemenu-collapse-open.hide {
    opacity: 0;
}

.sidemenu-collapse-close.hide {
    opacity: 0;

}

.sidemenu-darkmode {
    background-color: var(--secondary-color);
    border: 2px solid #ffffffa6;
    outline: .5px solid var(--secondary-color);
    border-radius: 100%;
    position: absolute;
    bottom: 5px;
    right: -11px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

}

.sidemenu-darkmode-dark,
.sidemenu-darkmode-light {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.2s;
}

.sidemenu-darkmode-dark>span,
.sidemenu-darkmode-light>span {
    color: #ffffffa6;
    border-radius: 50px;
}

.sidemenu-darkmode-dark.hide {
    opacity: 0;
}

.sidemenu-darkmode-light.hide {
    opacity: 0;

}

.sidemenu-background {
    background-color: #262e37;
    /* background-color: #181818; */
    position: relative;
    /* min-height: 100%; */
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.sidemenu-background .ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sidemenu-top,
.sidemenu-bottom {
    opacity: 1;
    position: sticky;
    z-index: 999;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}

.sidemenu-top-hide,
.sidemenu-bottom-hide {
    opacity: 0;
}

.sidemenu-top {
    top: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.sidemenu-bottom {
    bottom: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}

.sidemenu-logo-span {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    transition: padding 0.2s ease-in-out;
}

.ant-layout-sider-collapsed .sidemenu-logo-span {
    padding: 15px 18.5px;
}

.sidemenu-logo-full {
    width: 260px;
}

.sidemenu-logo-text {
    margin-left: 10px;
    max-width: 60%;
    max-height: 60%;
}

.sidemenu-divider {
    width: 90%;
    margin: auto;
    height: 1px;
    background-color: var(--light-color);
    margin-bottom: 10px;
}

.sidemenu-submenu-divider {
    margin: 0;
    border-color: #ffffff91;
}

.sidemenu-submenu {
    display: flex;
    align-items: center;
}

.sidemenu-submenu>.anticon {
    font-size: 20px !important;
}

.sidemenu-submenu-user>.ant-menu-submenu-title {
    padding-left: 15px !important;
}

.sidemenu-submenu-user {
    cursor: default;
}

.sidemenu-submenu-user p {
    display: flex;
    margin: 0px 10px 0px 10px;
    overflow: hidden;
    max-width: 140px;
    position: relative;
}

.sidemenu-submenu-user p span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.sidemenu-submenu-admin svg {
    width: 14px;
    height: 14px;
}

.sidemenu-submenu .anticon-user {
    /* Display bug with sideMenu's avatar */
    margin-right: 0px;
}

.sidemenu-swisskischool span {
    display: flex;
    align-items: center;
}

.sidemenu-swisskischool:hover>.sidemenu-image {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(94deg) brightness(200%) contrast(102%);
}

.sidemenu-image {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.sidemenu-image-collapsed {
    height: 24px;
    width: 24px;
}

.language-switcher-menu {
    background: #3c434b !important;
}

.language-switcher-item {
    color: #ffffffa6 !important;
}

.language-switcher-item:hover {
    color: #ffffff !important;
    background: #3c434b !important;
}

.language-switcher-item.selected {
    background: #C09F50 !important;
    color: #ffffff !important;
}

.side-menu-new {
    color: rgb(0, 0, 255);
    border: 1px solid rgb(0, 0, 255);
    padding: 1px 5px;
    font-size: 85%;
    padding-bottom: 2px;
    background-color: rgb(187, 187, 255);
    border-radius: 100px;
}

.ant-menu-item-selected .side-menu-new {
    display: none;
}