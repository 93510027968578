.mission-control-mandate {
    padding-left: 1rem;
}

.mission-control-mission {
    padding-left: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    gap: .5rem;
}

.mission-control-mission-name {
    flex: 1;
}

.mission-control-customer,
.mission-control-mandate,
.mission-control-mission-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mission-control-customer {
    margin-top: 1rem;
}

.mission-control-indicator-line {
    display: flex;
    align-items: center;
    gap: 5px;
}

.mission-control-indicator-user {
    padding-left: 1rem;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
}

.mission-control-list {
    flex: 1;
    max-height: 100%;
    overflow: auto;
}

.mission-control-container {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.mission-control-list .ant-tree-node-content-wrapper {
    overflow: auto;
    min-height: 35px;
    gap: 5px;
    display: flex;
    align-items: center;
}

.mission-control-list .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mission-control-list .ant-tree-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.mission-control-list .ant-tree-indent-unit {
    /* width: 16px; */
}

.mission-control-list .ant-tree-treenode-selected::before {
    background-color: rgba(190, 161, 86, 0.5) !important;
    /** TODO: Change color to this lighter color on selections and avatars (maybe buttons) **/
}

.mission-control-list .ant-tree-treenode-selected {
    color: black;
}

.mission-control-list-not-selectable,
.mission-control-list-not-selectable * {
    cursor: initial !important;
}

.mission-control-list-not-selectable .__mp-one-cell-content * {
    cursor: pointer !important;
}

.mission-control-list-not-selectable .ant-tree-title {
    flex: auto;
}

.mission-control-list-not-selectable:hover {
    color: red;
}

.mission-control-user-event {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 5px;
    margin-top: 8px;
    width: 100%;
    transition: background .1s ease-in-out;
    border-radius: 10px;
}

.mission-control-user-event,
.mission-control-user-event * {
    cursor: pointer !important;
}

.mission-control-list-not-selectable .ant-tree-node-content-wrapper {
    padding: 10px 5px;
}

.mission-control-event-create,
.mission-control-event-create * {
    cursor: pointer !important;
}

.mission-control-event-create {
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-top: 5px;
    transition: background .1s ease-in-out;
    border-radius: 10px;
    background: var(--primary-color) !important;
}

.mission-control-event-create:hover {
    background: rgba(190, 161, 86, 0.7) !important;
}