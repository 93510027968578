/* Configurations */
.configurations-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    padding-bottom: 10px;
    /*to give space to the cards' shadow*/
    flex-wrap: wrap;
}

.configurations-card {
    width: 47%;
    margin: 0px 10px 40px 10px;
}

.contract-select {
    width: 190px !important;
    margin-right: 10px;
}

.configurations-section-field {
    width: 300px !important;
}

.configurations-section-field-small {
    margin-left: 5px;
    width: 150px !important;
}

.configurations-width-100 {
    width: 100%;
}

.configurations-modal {
    min-width: 620px;
}

.report-modal {
    min-width: 1900px;
}

/* Types of day */

.types-of-day-card {
    width: 100%;
}

.types-of-day-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.contract-list {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.holidays-list {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

.types-of-day-list {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    max-height: calc(100vh - 194px);
    min-height: 300px;
    overflow-y: auto;
}

/* Occupancy rate */

.occupancy-rate-list {
    max-height: 400px;
    overflow: scroll;
}

.occupancy-rate-card-details {
    position: sticky;
    top: 0px;
    animation: fade-out 0.5s reverse;
}



.occupancy-rate-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.occupancy-rate-tod:first-child {
    margin-top: 5px
}

.occupancy-rate-section:not(:first-child) {
    margin-top: 20px;
}

/* Holidays */

.holidays-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
}

.holidays-holidays {
    margin: 20px 0px 20px 0px;
}

.holidays-holidays-checkboxes,
.holidays-holidays-checkboxes-readonly {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    line-height: 35px;
}

.holidays-holidays-checkboxes-readonly label,
.holidays-holidays-checkboxes-readonly input {
    cursor: default;
}

.holidays-holidays-checkboxes-readonly .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--border-color);
    border-color: var(--border-color);
}

.holidays-holidays-checkboxes-readonly:hover .ant-checkbox-checked::after {
    border: none;
}

.holidays-save-button {
    animation: expand-appear 1s forwards;
    max-width: 150px;
}

@media only screen and (max-width: 900px) {
    .types-of-day-card {
        width: 100%;
    }

    .occupancy-rate-list .occupancy-rate-section {
        display: flex;
        align-items: center;
        justify-content: left;
    }
    
    .occupancy-rate-section:not(:first-child) {
        margin-top: 10px;
    }

    .occupancy-rate-list .occupancy-rate-section p {
        text-align: justify;
    }

    .occupancy-rate-list .occupancy-rate-section p:first-child {
        margin-right: 5px;
        font-weight: bold;
    }

    .occupancy-rate-list .occupancy-rate-section:first-child p {
        font-weight: normal;
    }

    .types-of-day-content {
        flex-direction: column;
        align-items: start;
    }

    .configurations-content {
        flex-direction: column !important;
        align-items: baseline;
        flex-direction: row;
        padding-bottom: 10px;
        /*to give space to the cards' shadow*/
    }

    .configurations-card {
        width: 100%;
        margin: 20px 0px 20px 0px;
    }

    .configurations-section-field {
        width: 150px !important
    }

    .configurations-modal,
    .report-modal {
        min-width: 95% !important;
    }
}