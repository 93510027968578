.skeleton-parent {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.skeleton-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 10px 20px 0px 0px;
    z-index: 1;
}

.skeleton-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.skeleton-title {
    width: 70%;
    height: 20px;
    margin-bottom: 10px;
}

.skeleton-paragraph-container {
    width: 100%;
    height: 100%;
}

.skeleton-paragraph {
    margin: 10px 0px;
    height: 16px;
    width: 100%;
}

.skeleton-animation {
    background: -webkit-gradient(linear, left top, right top, color-stop(25%, #dbdbdb), color-stop(37%, #b2b2b2), color-stop(63%, #dbdbdb));
    background: linear-gradient(90deg, #dbdbdb 25%, #b2b2b2 37%, #dbdbdb 63%);
    background-size: 400% 100%;
    -webkit-animation: skeleton-loading 1.4s ease infinite;
    animation: skeleton-loading 1.4s ease infinite;
}


@-webkit-keyframes skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}