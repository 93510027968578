body {
    background-color: var(--background-color);
}

.splashScreen .spinnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.splashScreen .spinner {
    width: 56px;
    height: 56px;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--primary-color);
    animation: tri-spinner 1s infinite linear;
}

.splashScreen .spinner::before,
.splashScreen .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: tri-spinner 2s infinite;
}

.splashScreen .spinner::after {
    margin: 8px;
    animation-duration: 3s;
}

@keyframes tri-spinner {
    100% {
        transform: rotate(1turn);
    }
}

.splashScreen .loader {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    display: flex;
    margin-left: 90px;
    gap: 5px;
}


.splashScreen .loader p {
    font-weight: normal;
}

.splashScreen .words {
    overflow: hidden;
    position: relative;
    width: 200px;
}

.splashScreen .word {
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--primary-color);
    transition: margin-top .5s;
    transition-delay: 0s;
    margin-top: 40px;
}

.splashScreen .word.isLoading {
    margin-top: 0;
}

.splashScreen .word.isProcessed {
    margin-top: -40px;
}