.__pr_work_item_title_cell {
    display: flex;
    align-items: center;
}

.__pr_work_item_title_cell>div:first-child {
    width: 30px;
    text-align: center;
}

.__pr_work_item_action_cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px
}

.__wic_actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.__wic-default-text {
    font-style: italic;
    color: grey;
}

.__wic-add-row {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}

.__wic-create-select {
    width: 100%;
}

.__wic-creation-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.__wic-work-item-create-form {
    display: flex;
    align-items: center;
    gap: 10px;
}