.phone-overview-parent {
    display: grid;
    grid-gap: 40px;
}

@media screen and (max-width: 800px) {
    .phone-overview-parent {
        display: inline-flex;
        max-width: 100%
    }

    .phone-overview-mobile-input {
        max-width: 100%;
        word-wrap: break-word;
    }
}

.phone-overview-card:first-child {
    grid-row: 1;
    grid-column: 1 / span 2;
}

.phone-overview-card:nth-child(2) {
    grid-row: 1 / span 2;
    grid-column: 3;
}

.phone-overview-card:last-child {
    grid-row: 2 / span 3;
    grid-column: 1 / span 2;
}

.phone-overview-list-item {
    display: flex;
}

.phone-overview-list-item p {
    width: 33%;
    font-weight: normal;
}

.phone-overview-contact-input {
    margin: 20px 0px;
    width: 100%;
}

.phone-overview-contact-input:first-child {
    margin-top: 0px;
}

.phone-overview-contact-input:last-child {
    margin-bottom: 0px;
}

.phone-overview-mobile-section-title {
    border-bottom: 1px solid var(--border-color);
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.phone-overview-mobile-section-line {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
}

.phone-overview-mobile-section-line p:first-child {
    margin-right: 10px;
}

.phone-overview-mobile-input {
    width: 300px;
}

.phone-overview-phone {
    margin: auto;
    border-radius: 30px;
    border: 8px solid black;
    width: 240px;
    height: 500px;
    background-color: #e4e4e4;
    position: relative;
}

.phone-overview-phone-navbar {
    border-radius: 22px 22px 0px 0px;
    background-color: var(--primary-color);
    color: white;
    height: 40px;
    position: relative;
}
.phone-overview-phone-navbar .phone-overview-phone-navbar-pictos {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    height: 40px;

}
.phone-overview-phone-navbar .phone-overview-phone-navbar-title-name {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    height: 40px;
}

.phone-overview-phone-header {
    min-height: 40px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    align-items: center;
    font-size: 11px;
}
.phone-overview-phone-footer {
    border-radius: 0px 0px 20px 20px;
    background-color: #F2F2F2;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #740000;
    font-weight: 600;
}
.phone-overview-phone-plus-button{
        position: absolute;      
        bottom: 50px;
        right: 7px;
        background-color: var(--primary-color);
}
.phone-overview-phone-likes-button-container{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 8px
}
.phone-overview-phone-likes-button{

border-radius: 8px;
  background-color: var(--planning-outside-period);
  padding-top: 2px;
  display: flex;
  align-content: center;
  align-items: center;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  gap: 4px;
}
.phone-overview-phone-news-block{
    background-color: white;
    border-radius: var(--border-radius);
    padding: 5px;
    margin: 4px;
}
.phone-overview-phone-news-block-title{
    display: flex;
}
.phone-overview-phone-news-block-content-title{
    font-size: 9px;
    margin-top: 8px;
}

.phone-overview-phone-news-details,
.phone-overview-phone-news-details2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
}
.phone-overview-phone-news-details span,
.phone-overview-phone-news-details2 span{
    font-size: 9px;
}
.phone-overview-phone-news-details span:first-child{
    font-weight: 600;
}
.phone-overview-phone-news-details2 span:first-child{
    font-weight: 100;
    font-size: 8px;
}
.phone-overview-phone-header .ant-input-group-addon {
    border: none;
    background-color: white;
    padding-right: 8px;
    font-size: 12px;
}
.phone-overview-phone-header .ant-input {
    border: none;
    padding-left: 0;
    font-size: 12px;
    line-height: 26px;
    }

.phone-overview-phone-contact {
    margin: 10px 0px;
    padding: 0px 10px;
    font-size: 14px;
    background-color: white;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.phone-overview-phone-dots {
    display: flex;
    justify-content: center;
    font-size: 30px;
}

.phone-overview-phone-button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.phone-overview-phone-button {
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    padding: 5px;
}

@media only screen and (max-width: 900px) {
    .phone-overview-parent {
        flex-direction: column;
    }

    .phone-overview-card {
        width: 100%;
    }
}

.root-overview .overview-input-number .ant-input-number{
    min-width: 60px;
    width: auto;
    max-width: 150px;
}