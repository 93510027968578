.login-background-image {
    background-image: url('../images/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
}

.login-background {
    background-color: rgba(12, 12, 12, 0.8);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
}

.login-rights {
    width: 80%;
    padding: 5px 0px;
    text-align: center;
    color: var(--light-color);
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px 5px 0px 0px;
}

.login-parent {
    width: 400px;
    height: 450px;
    z-index: 9999999999;
}

.login-content {
    background-color: white;
    /* border-radius: 5px; */
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow);
}

.login-content .ant-input-wrapper {
    display: flex;
}

.login-content .ant-input-group-addon {
    width: 50px;
    padding-top: 2px;
}

.login-header {
    border-radius: 5px;
    margin: 20px auto 0 auto;
    /* background-color: #211c1c; */
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--light-color);
}

.login-logo {
    width: 80%;
    max-height: 100%;
}

.login-body {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-input:first-child {
    margin: 25px auto 10px auto;
    width: 80%;
}

.login-input {
    margin: 0px auto 10px auto;
    width: 80%;
}

.login-submit {
    width: 50%;
    margin: 20px 0px 0px 0px;
}

.login-footer-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 0px 20px 0px;
}

@media only screen and (max-width: 500px) {
    .login-rights {
        width: 300px;
    }

    .login-parent {
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}