/* Global */

.text-disable-select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.divider {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
}

.both-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-sp {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row-sp,
.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-row-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.flex-row-wrap-2 {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  /* Your container needs a fixed height, and it 
   * needs to be taller than your tallest column. */
  height: 1650px;
}

/* .item {
  width: 50%;
  box-sizing: border-box;
 
}

.item:nth-of-type(2n) { order: 1; }
.item:nth-of-type(2n+1) { order: 2; } */

/* Force new columns */
.flex-row-wrap-2::before,
.flex-row-wrap-2::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}

.__new-right-panel,
.__new-right-panel .container-content-background {
  background-color: white;
}

.__new-right-panel .container-content-background {
  padding: 10px 20px 20px 20px;
}

.flex-row-wrap-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.__filter-to-table {
  margin-bottom: 10px;
}

.__center-vertically {
  display: flex;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-tags {
  margin: 0px 5px 4px 5px;
  background-color: #e4e4e4;
  border-radius: var(--border-radius);
}

.tab-tooltip-info {
  position: absolute;
  margin-left: 3px;
  font-size: 12px;
  cursor: pointer;
}

.space-tooltip {
  position: relative;
}

.tab-tooltip-info-new {
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  top: 0;
}

.bold-submenu .ant-dropdown-menu-submenu-title {
  font-weight: bold;
}

.bold-submenu-item.ant-dropdown-menu-item {
  font-weight: bold;
}

/* Card Header */

.card-header-circle-button {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 2;
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
}

.card-header-circle-button:hover {
  background-color: var(--background-color);
}

.card-header-circle-button svg {
  width: 21px;
  height: 21px;
}

/* NotFound */

.notfound-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  widows: 100%;
}

.notfound-text {
  font-size: 30px;
}

.notfound-logo {
  width: 80%;
  margin-bottom: 100px;
}

.checkbox-readonly {
  cursor: default;
}

.checkbox-readonly .ant-checkbox-input {
  cursor: default;
}

.checkbox-readonly .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--border-color);
  border-color: var(--border-color);
}

.checkbox-readonly:hover .ant-checkbox-checked::after {
  border: none;
}

.checkbox-readonly:hover .ant-checkbox-inner {
  border-color: var(--border-color);
}

.rpv-core__viewer {
  /* max-width: 1920px; */
  height: calc(100vh - 105px) !important;
  min-height: 600px;
}

.rpv-core__viewer .rpv-default-layout__container {
  border-radius: var(--border-radius);
}

.rpv-core__viewer .rpv-default-layout__toolbar {
  border-radius: 18px 18px 0px 0px;
}

.rpv-core__viewer .rpv-default-layout__main {
  border-radius: 0px 0px 18px 18px;
}

.__zoom-avatar {
  transition: transform .2s;
  z-index: 3;
}

.__zoom-avatar:hover {
  transform: scale(1.5);
  /* transform-origin: left; */
  z-index: 4;
}

.__datepicker-year {
  width: 90px;
}

.react-time-picker__wrapper {
  border: 1px solid var(--border-color);
  border-radius: 30px;
  padding-left: 10px;
}

.react-time-picker__clock {
  border-radius: var(--border-radius);
  border-color: var(--border-color);
}

.react-time-picker:focus-visible {
  border: none !important
}

.__hover-zoom {
  transition: transform .3s ease;
  cursor: pointer;

}

.__hover-zoom:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 900px) {
  .flex-sp {
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-row-sp {
    flex-direction: column;
  }

  .modal-button-next-previous-title {
    display: none !important;
  }
}

.info-tooltip-icon {
  font-size: 12px;
  margin-left: 3px;
  margin-top: -10px;
}

p.error {
  color: red;
  font-weight: bold;
}