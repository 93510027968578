.leaflet-container-crosshair {
    cursor: crosshair !important;
}

.leaflet-container-grab {
    cursor: grab !important;
}

.leaflet-popup-content {
    text-align: center;
    margin: 10px 12px !important;
}

.__fullscreen-map-minimized {
    height: 100%;
}

.leaflet-control-zoom {
    margin: 20px 0 0 0 !important;
    border: none !important;
}

.leaflet-control-zoom-in {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border: 0px !important;
    height: 38px !important;
    width: 38px !important;
}

.leaflet-control-zoom-in:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.leaflet-control-zoom-out {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 4px !important;
    border: 0px !important;
    height: 38px !important;
    width: 38px !important;
}

.leaflet-control-zoom-out:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
}


.__fullscreen-map-button,
.__fullscreen-live-map-button,
.__refresh-map-button,
.__filter-map-button {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 110px !important;
    left: 0px !important;
    z-index: 999;
    height: 38px !important;
    width: 38px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 4px !important;
    /* background-color: #ffffff!important; */
    border: 0px !important;
    padding: 0px;
}

.__fullscreen-live-map-button {
    border-bottom-right-radius: 0px !important;
}

.__refresh-map-button {
    top: 148px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.__filter-map-button {
    top: 186px !important;
    border-top-right-radius: 0px !important;
}


.__fullscreen-map-button:hover,
.__fullscreen-live-map-button:hover,
.__refresh-map-button:hover,
.__filter-map-button:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.__refresh-map-button:focus,
.__filter-map-button:focus {
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
}


.leaflet-touch .leaflet-bar a {
    line-height: 38px !important;
}

.leaflet-control-layers {
    margin: 20px 0 0 0 !important;
    border: none !important;
    border-radius: 4px 0 0 4px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.leaflet-touch .leaflet-control-layers-toggle {
    height: 38px !important;
    width: 38px !important;
}

.leaflet-control-layers-toggle {
    background-size: 20px;
}

.__leaflet-popup-content p {
    text-align: left;
    line-height: 4px;
}

a.leaflet-popup-close-button {
    color: white !important;
}

.leaflet-popup-tip {
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.leaflet-popup-content-wrapper {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    /* background-color: #ffffffec!important; */
    border-radius: 4px !important;
}

.leaflet-control-layers {
    text-align: left;
}

.leaflet-container a.leaflet-popup-close-button:hover {
    /* padding: 5px 5px 0 0!important; */
    color: #c78034 !important;
}

.__leaflet-popup-content .__leaflet-popup-action {
    text-align: center;
}